export const STATUS_BADGE = {
  PENDING: {
    text: 'Pending',
    color: 'warning',
  },
  MONITOR_ONLY: {
    text: 'Monitor Only',
    color: 'info',
  },
  ACTIVE: {
    text: 'Active',
    color: 'success',
  },
  SUSPENDED: {
    text: 'Suspended',
    color: 'danger',
  },
  TERMINATED: {
    text: 'Terminated',
    color: 'secondary',
  },
};

export const PROFILE_STATUS_BADGE = {
  NOT_INITIALIZAD: {
    text: 'Not Initialized',
    color: 'danger',
  },
  NOT_SUFFICIENT_HISTORY: {
    text: 'Non-sufficient history',
    color: 'warning',
  },
  OK: {
    text: 'Ok',
    color: 'success',
  },
  OUT_OF_DATE: {
    text: 'Out of date',
    color: 'secondary',
  },
};

export const FUNDING_SOURCE_BADGE = {
  YES: {
    text: 'Yes',
    color: 'success',
  },
  NO: {
    text: 'No',
    color: 'warning',
  },
  NA: {
    text: 'N/A',
    color: 'secondary',
  },
};

export const PAYROLL_STATUS_BADGE = {
  NA: {
    text: 'N/A',
    color: 'secondary',
  },
  NOT_DETECTED: {
    text: 'Not Detected',
    color: 'danger',
  },
  UNKNOWN_FREQUENCY: {
    text: 'Unknown Frequency',
    color: 'warning',
  },
  OK: {
    text: 'Ok',
    color: 'success',
  },
  NOT_RECENT: {
    text: 'Not Recent',
    color: 'danger',
  },
  OUT_OF_DATE: {
    text: 'Out of date',
    color: 'secondary',
  },
};

export const BANK_ACCOUNT_STATUS_BADGE = {
  CONNECTED: {
    text: 'Connected',
    color: 'bg-success',
  },
  NOT_CONNECTED: {
    text: 'Not Connected',
    color: 'bg-warning',
  },
  REFRESH_REQUIRED: {
    text: 'Refresh Required',
    color: 'bo-badge-bg-border-red',
  },
  INVALID: {
    text: 'Invalid',
    color: 'bg-danger',
  },
  NO_TRANSFERS: {
    text: ' No Transfers',
    color: 'bg-info',
  },
};

export const INSTITUTION_CATEGORY_BADGE = {
  A: {
    text: 'A',
    color: 'bo-badge-bg-border-green',
  },
  B: {
    text: 'B',
    color: 'bo-badge-bg-border-green',
  },
  C: {
    text: 'C',
    color: 'bo-badge-bg-border-green',
  },
  D: {
    text: 'D',
    color: 'bo-badge-bg-border-yellow',
  },
};

export const JOB_STATUS_BADGE = {
  SUCCESS: {
    text: 'Succeeded',
    color: 'success',
  },
  RUNNING: {
    text: 'Running',
    color: 'info',
  },
  FAILED: {
    text: 'Failed',
    color: 'danger',
  },
  PENDING_ASSET_REPORT: {
    text: 'Pending Asset Report',
    color: 'warning',
  },
  QUEUE: {
    text: 'In Queue',
    color: 'warning',
  },
};

export const CARD_ASTRA_STATUS = {
  approved: {
    text: 'approved',
    color: 'success',
  },
  pending: {
    text: 'pending',
    color: 'warning',
  },
};

export const CARD_VERIFICATION_STATUS = {
  PENDING: {
    text: 'Pending',
    color: 'warning',
  },
  VERIFIED: {
    text: 'Verified',
    color: 'success',
  },
  UNVERIFIED: {
    text: 'Unverified',
    color: 'danger',
  },
};

export const CARD_STATUS = {
  OK: {
    text: 'Ok',
    color: 'success',
  },
  EXPIRED: {
    text: 'Expired',
    color: 'danger',
  },
  INVALID: {
    text: 'Invalid',
    color: 'danger',
  },
  CANCELED: {
    text: 'Canceled',
    color: 'danger',
  },
};

export const CONSUMER_NO_OFFER_REASON = {
  OPEN_FUNDING_IS_DUE: 'Open advance is due',
  NO_PREDICTED_PAYDAY: "Can't predict next payday",
  NO_AVAILABLE_LIMIT: 'No available limit',
  CONSUMER_NOT_ACTIVE: 'Consumer not active',
  NO_EMPLOYER: 'No employer',
  CONSUMER_PAYROLL_STATUS: 'Payroll status does not allow',
  NOT_ENOUGH_PAYCHECKS: 'Not enough paychecks',
  EMPLOYMENT_PERIOD_TOO_SHORT: 'Employment period is too short',
  OPEN_FUNDING_IS_CLOSING: "Open advance in 'Closing' state",
  NO_FUNDING_SOURCE: 'No funding source',
  NON_SUFFICIENT_HISTORY: 'Non-sufficient history',
  PROFILE_OUT_OF_DATE: 'Profile is out-of-date',
  NO_PAYMENTS_DETECTED: 'No payments detected',
  NON_TOPUP_REPAYMENT_MODE: 'Repayment mode does not allow top-up',
  INSUFFICIENT_BRIDGEPOINTS: 'Insufficient Bridgepoints',
  CARD_NOT_AVAILABLE: 'No card on file',
  INVALID_CARD: 'Main card is invalid',
  PAYROLL_NOT_RECENT: 'Recent payroll is missing',
  CONSUMER_SERVICE_TYPE: 'CONSUMER_SERVICE_TYPE',
  UNKNOWN: 'Unknown reson',
};

export const CONSUMER_JOB_STATUS = {
  QUEUE: 'Waiting in queue',
  PENDING_ASSET_REPORT: 'Waiting for assets report',
  RUNNING: 'Running',
  SUCCESS: 'Finished successfully',
  FAILED: 'Failed',
  UNKNOWN: 'Unknown',
};
