import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import { Spinner } from '../../../../components/Spinner';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import CollapsedPanel from '../../../../components/CollapsedPanel';
// shared
import NewObjectAccordion from '../shared/NewObjectAccordion';
import NewObjectSelectInput from '../shared/NewObjectSelectInput';
import NewObjectTextInput from '../shared/NewObjectTextInput';
import NewObjectMultiLineTextInput from '../shared/NewObjectMultiLineTextInput';
import NewObjectDefaultTextInput from '../shared/NewObjectDefaultTextInput';
// forms
import NewObjectPopoverNameForm from './forms/NewObjectPopoverNameForm';
import NewObjectPopoverAuthenticateForm from './forms/NewObjectPopoverAuthenticateForm';
import NewObjectPopoverStatusForm from './forms/NewObjectPopoverStatusForm';
import NewObjectPopoverSearchAggregatorForm from './forms/NewObjectPopoverSearchAggregatorForm';
import NewObjectPopoverPhoneNumberForm from './forms/NewObjectPopoverPhoneNumberForm';
import NewObjectPopoverAddressForm from './forms/NewObjectPopoverAddressForm';
import NewObjectPopoverDateOfBirthForm from './forms/NewObjectPopoverDateOfBirthForm';
// widgets
import NewObjectRedFlagsWidget from './widgets/NewObjectRedFlagsWidget';
import NewObjectBridgePointsWidget from './widgets/NewObjectBridgePointsWidget';
import NewObjectOpenFundingWidget from './widgets/NewObjectOpenFundingWidget';
import NewObjectActiveOfferWidget from './widgets/NewObjectActiveOfferWidget';

function NewObjectDetailsPanel({
  collapsedPanels,
  expandParentPanel,
  loading,
  setJobId,
  jobStatus,
  refreshConsumer,
  consumer,
  setConsumer,
  aggregators,
  bridgePointsBalance,
  openFunding,
  prediction,
  panelDataRefresh,
  setPanelDataRefresh,
}) {
  const [spinner, setSpinner] = useState(false);

  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const refresh = useCallback(async () => {
    setSpinner(true);
    await refreshConsumer(consumer.id);
    setSpinner(false);
  }, [refreshConsumer, consumer?.id]);

  useEffect(() => {
    if (panelDataRefresh.consumer) {
      refresh();
      setPanelDataRefresh(prev => ({ ...prev, consumer: false }));
    }
  }, [panelDataRefresh, refresh, setPanelDataRefresh]);

  const resetReferral = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }
    const config = {
      token,
      url: `${apiOriginConsumer}/reset_referral`,
      method: 'POST',
      data: JSON.stringify({ consumerId: consumer.id }),
    };

    try {
      await makeRequest(config);
      setConsumer(prevConsumer => ({ ...prevConsumer, referringConsumerId: '' }));
      toast.success('Referring consumer has been reset!');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const setReferral = async value => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }
    const config = {
      token,
      url: `${apiOriginConsumer}/set_referral`,
      method: 'POST',
      data: JSON.stringify({ consumerId: consumer.id, referringConsumerId: value }),
    };

    try {
      await makeRequest(config);
      setConsumer(prevConsumer => ({ ...prevConsumer, referringConsumerId: value }));
      toast.success('Referring consumer has been updated!');
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const updateConsumer = useCallback(
    async fields => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/update_consumer`,
        method: 'POST',
        data: JSON.stringify({ consumerId: consumer.id, ...fields }),
      };

      try {
        const response = await makeRequest(config);

        if (response.status === 'ok') {
          setConsumer(prevConsumer => ({ ...prevConsumer, ...fields }));
        }

        if (response.jobId !== null) {
          setJobId(response.jobId);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getAccessTokenSilently, apiOriginConsumer],
  );

  if (collapsedPanels.details) {
    return <CollapsedPanel side="right" onClick={() => expandParentPanel(0)} />;
  }

  return (
    <Spinner visible={spinner}>
      <div className="h-100">
        <div className="py-2 px-3">
          <div className="d-flex justify-content-between align-items-center">
            <NewObjectPopoverNameForm
              consumerFirstName={consumer.firstName}
              consumerLastName={consumer.lastName}
              updateConsumer={updateConsumer}
            />
            <div className="d-flex align-items-center">
              <NewObjectPopoverStatusForm
                consumerStatus={consumer.status}
                updateConsumer={updateConsumer}
              />
              <Button className="bo-new-object-base-button pb-1" onClick={refresh}>
                <FontAwesomeIcon icon={faArrowRotateRight} size="sm" color="#5946A4" />
              </Button>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center pb-1">
            <p className="bo-new-object-text p-0 m-0">{`ID: ${consumer.id}`}</p>
            <NewObjectPopoverAuthenticateForm
              consumerId={consumer.id}
              consumerMfaChannel={consumer.mfaChannel}
            />
          </div>
          <NewObjectPopoverSearchAggregatorForm
            consumerAggregatorId={consumer.aggregatorId}
            updateConsumer={updateConsumer}
            aggregators={aggregators}
          />
          <p className="bo-new-object-text p-0 m-0 pb-1">{consumer.email}</p>
          <div className="my-2 d-flex flex-column align-items-center gap-2">
            {consumer.flags.length > 0 && (
              <NewObjectRedFlagsWidget consumerFlags={consumer.flags} />
            )}
            <NewObjectBridgePointsWidget
              loading={loading}
              bridgePointsBalance={bridgePointsBalance}
            />
            {openFunding && openFunding.id && (
              <NewObjectOpenFundingWidget openFunding={openFunding} />
            )}
            <NewObjectActiveOfferWidget
              loading={loading}
              jobStatus={jobStatus}
              setJobId={setJobId}
              refreshConsumer={refresh}
              consumerId={consumer.id}
              openFunding={openFunding}
              prediction={prediction}
            />
          </div>
        </div>
        <NewObjectAccordion title="Personal and Contact Details">
          <NewObjectPopoverPhoneNumberForm
            consumerId={consumer.id}
            consumerPhoneCc={consumer.phoneCc}
            consumerPhoneNdc={consumer.phoneNdc}
            consumerPhoneSn={consumer.phoneSn}
            consumerIsPhoneVerified={consumer.isPhoneVerified}
            setConsumer={setConsumer}
          />
          <NewObjectPopoverAddressForm
            consumerAddressLine1={consumer.addressLine1}
            consumerAddressLine2={consumer.addressLine2}
            consumerCity={consumer.city}
            consumerState={consumer.state}
            consumerZip={consumer.zip}
            updateConsumer={updateConsumer}
          />
          <NewObjectPopoverDateOfBirthForm
            consumerDateOfBirth={consumer.dateOfBirth}
            updateConsumer={updateConsumer}
          />
          <NewObjectTextInput
            label="Last 4 SSN digits"
            initialValue={consumer.last4ssn}
            labelClassName="mt-3"
            maxLength={4}
            type="numeric"
            onSave={value => updateConsumer({ last4ssn: value })}
          />
          <NewObjectSelectInput
            label="Language"
            initialValue={consumer.language}
            labelClassName="mt-3"
            options={[
              { key: 'en', value: 'en', name: 'English' },
              { key: 'es', value: 'es', name: 'Spanish' },
            ]}
            onSave={value => updateConsumer({ language: value })}
          />
          <NewObjectTextInput
            label="Employee ID"
            initialValue={consumer.employeeId}
            labelClassName="mt-3"
            onSave={value => updateConsumer({ employeeId: value })}
          />
        </NewObjectAccordion>
        <NewObjectAccordion title="Bridgeover Service">
          <NewObjectTextInput
            label="Registration Time"
            readOnly
            initialValue={moment
              .utc(consumer.createDate)
              .local()
              .format('MMM DD, YYYY HH:mm (UTCZ)')}
          />
          <NewObjectTextInput
            label="Referred By"
            initialValue={consumer.referringConsumerId}
            labelClassName="mt-3"
            type="numeric"
            onSave={value => setReferral(value)}
            onDelete={() => resetReferral()}
          />
          <NewObjectTextInput
            label="MFA Channel"
            readOnly
            labelClassName="mt-3"
            initialValue={consumer.mfaChannel}
          />
          <NewObjectSelectInput
            label="Default Payment Method"
            initialValue={consumer.defaultPaymentMethod}
            labelClassName="mt-3"
            options={[
              { key: 'ACH', value: 'ACH', name: 'ACH' },
              { key: 'CARD', value: 'CARD', name: 'CARD' },
            ]}
            onSave={value => updateConsumer({ defaultPaymentMethod: value })}
          />
          <NewObjectSelectInput
            label="Card Required For Taking an Advance"
            initialValue={consumer.cardRequired ? 'Yes' : 'No'}
            labelClassName="mt-3"
            options={[
              { key: 'Yes', value: 'Yes', name: 'Yes' },
              { key: 'No', value: 'No', name: 'No' },
            ]}
            onSave={value => updateConsumer({ cardRequired: value === 'Yes' })}
          />
          <NewObjectSelectInput
            label="Allow Credit Card"
            initialValue={consumer.allowCreditCard ? 'Yes' : 'No'}
            labelClassName="mt-3"
            options={[
              { key: 'Yes', value: 'Yes', name: 'Yes' },
              { key: 'No', value: 'No', name: 'No' },
            ]}
            onSave={value => updateConsumer({ allowCreditCard: value === 'Yes' })}
          />
          <NewObjectMultiLineTextInput
            label="Notes"
            labelClassName="mt-3"
            initialValue={consumer.notes}
            onSave={value => updateConsumer({ notes: value })}
          />
          <NewObjectSelectInput
            label="High Risk"
            initialValue={consumer.highRisk ? 'Yes' : 'No'}
            labelClassName="mt-3"
            options={[
              { key: 'Yes', value: 'Yes', name: 'Yes' },
              { key: 'No', value: 'No', name: 'No' },
            ]}
            onSave={async value => {
              await updateConsumer({ highRisk: value === 'Yes' });
              refresh();
            }}
          />
          {consumer.highRisk && (
            <NewObjectMultiLineTextInput
              label="High Risk Reason"
              labelClassName="mt-3"
              initialValue={consumer.highRiskReason}
              onSave={async value => {
                await updateConsumer({ highRiskReason: value });
                refresh();
              }}
            />
          )}
        </NewObjectAccordion>
        <NewObjectAccordion title="Settings" bottomBorder>
          <NewObjectDefaultTextInput
            label="Free Credit Transfers"
            initialValue={consumer.defaultFreeCreditTransfers}
            onSave={value => updateConsumer({ defaultFreeCreditTransfers: value })}
          />
          <NewObjectDefaultTextInput
            label="Free Debit Transfers"
            labelClassName="mt-3"
            initialValue={consumer.defaultFreeDebitTransfers}
            onSave={value => updateConsumer({ defaultFreeDebitTransfers: value })}
          />
          <NewObjectDefaultTextInput
            label="Transfer Fee ($)"
            labelClassName="mt-3"
            initialValue={consumer.defaultTransferFee}
            decimalScale={2}
            onSave={value => updateConsumer({ defaultTransferFee: value })}
          />
          <NewObjectDefaultTextInput
            label="Monthly BridgePoints™ Grant"
            labelClassName="mt-3"
            initialValue={consumer.bpMonthlyQuota}
            onSave={value => updateConsumer({ bpMonthlyQuota: value })}
          />
          <NewObjectDefaultTextInput
            label="BridgePoints™ Cap"
            labelClassName="mt-3"
            initialValue={consumer.bpCap}
            onSave={value => updateConsumer({ bpCap: value })}
          />
          <NewObjectTextInput
            label="Max Advance ($)"
            initialValue={consumer.advanceLimit}
            labelClassName="mt-3"
            type="numeric"
            allowLeadingZeros={false}
            onSave={value => updateConsumer({ advanceLimit: value })}
            allowEmpty={false}
          />
          <NewObjectDefaultTextInput
            label="Min Payroll Cycles"
            labelClassName="mt-3"
            initialValue={consumer.minPayrollCycles}
            onSave={value => updateConsumer({ minPayrollCycles: value })}
          />
          <NewObjectDefaultTextInput
            label="Min Employment Period (weeks)"
            labelClassName="mt-3"
            initialValue={consumer.minPayrollWeeks}
            onSave={value => updateConsumer({ minPayrollWeeks: value })}
          />
          <NewObjectDefaultTextInput
            label="Min Account History (days)"
            labelClassName="mt-3"
            initialValue={consumer.minAccountDays}
            onSave={value => updateConsumer({ minAccountDays: value })}
          />
        </NewObjectAccordion>
      </div>
    </Spinner>
  );
}

export default NewObjectDetailsPanel;
