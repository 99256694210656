import axios from 'axios';
import moment from 'moment-timezone';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { formatTimeToCutOff, getNextCutOffTime } from '../utils/dates';
import { useAuth } from '../hooks/useAuth';
import { useEnv } from './env-context';
import { makeRequest } from '../utils/makeRequest';

const ClockContext = createContext();

export const useClockContext = () => useContext(ClockContext);

export function ClockProvider({ children }) {
  const { apiOriginOrchestrator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [mainTime, setMainTime] = useState(null);
  const [consumerLocation, setConsumerLocation] = useState({
    city: null,
    state: null,
    country: 'US',
  });
  const [consumerTimeLoading, setConsumerTimeLoading] = useState(false);
  const [consumerTimeZone, setConsumerTimeZone] = useState(null);
  const [consumerTime, setConsumerTime] = useState(null);
  const [closestNonBusinessDays, setClosestNonBusinessDays] = useState(null);
  const [timeToCutOff, setTimeToCutOff] = useState(null);

  const getConsumerTimeZone = useCallback(async () => {
    try {
      setConsumerTimeLoading(true);

      const { city, state, country } = consumerLocation;

      const cities = await axios({
        url: 'https://api.api-ninjas.com/v1/geocoding',
        headers: {
          'X-Api-Key': process.env.REACT_APP_NINJAS_API_KEY,
        },
        params: { city, state, country },
      });

      if (cities.data && cities.data.length > 0) {
        const timeZone = await axios({
          url: 'https://api.api-ninjas.com/v1/timezone',
          headers: {
            'X-Api-Key': process.env.REACT_APP_NINJAS_API_KEY,
          },
          params: { lat: cities.data[0].latitude, lon: cities.data[0].longitude },
        });

        if (timeZone.data && timeZone.data.timezone) {
          setConsumerTimeZone(timeZone.data.timezone);
        }
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setConsumerTimeLoading(false);
    }
  }, [consumerLocation]);

  const getNonBusinessDays = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/non_business`,
      method: 'GET',
      params: {
        from: moment().format('YYYY-MM-DD'),
        to: moment().add(7, 'days').format('YYYY-MM-DD'),
      },
    };

    try {
      const response = await makeRequest(config);

      setClosestNonBusinessDays(response);
    } catch (error) {
      console.error(error.message);
    }
  }, [apiOriginOrchestrator, getAccessTokenSilently]);

  useEffect(() => {
    getNonBusinessDays();
  }, [getNonBusinessDays]);

  useEffect(() => {
    if (consumerLocation.city && consumerLocation.state && consumerLocation.country) {
      getConsumerTimeZone();
    }
  }, [consumerLocation, getConsumerTimeZone]);

  useEffect(() => {
    const interval = setInterval(() => {
      const time = moment.utc().tz('America/New_York');

      setMainTime(time.format('hh:mma ') + time.zoneAbbr()[0] + time.zoneAbbr()[2]);
    }, 200);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let interval;

    if (consumerTimeZone) {
      interval = setInterval(() => {
        const time = moment.utc().tz(consumerTimeZone);

        setConsumerTime(time.format('hh:mma ') + time.zoneAbbr()[0] + time.zoneAbbr()[2]);
      }, 200);
    } else {
      setConsumerTime(null);
      clearInterval(interval);
    }

    return () => {
      setConsumerTime(null);
      clearInterval(interval);
    };
  }, [consumerTimeZone]);

  useEffect(() => {
    let interval;

    if (closestNonBusinessDays) {
      interval = setInterval(() => {
        const currentCT = moment().tz('America/Chicago');

        const nextCutOffTime = getNextCutOffTime(currentCT, closestNonBusinessDays);

        const remainingTime = nextCutOffTime.diff(currentCT);

        setTimeToCutOff(formatTimeToCutOff(remainingTime));
      }, 200);
    }

    return () => clearInterval(interval);
  }, [closestNonBusinessDays]);

  const value = useMemo(
    () => ({
      mainTime,
      consumerTime,
      consumerTimeLoading,
      timeToCutOff,
      setConsumerLocation,
      setConsumerTimeZone,
    }),
    [mainTime, consumerTime, consumerTimeLoading, timeToCutOff],
  );

  return <ClockContext.Provider value={value}>{children}</ClockContext.Provider>;
}
