import moment from 'moment';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../../../components/Spinner';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { useTableContext } from '../../../../context/table-context';
import StyledTableCell from '../../../../components/StyledTableCell';
import { Card, CardHeader, CardBody, Button, Row, Col } from 'reactstrap';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { toast } from 'react-toastify';

function DashboardJobsSummary() {
  const navigate = useNavigate();
  const { apiOriginOrchestrator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const { clearTableFilters, setSelectedConsumerIdsFilter } = useTableContext();

  const [report, setReport] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const getJobsReport = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/job/report`,
      method: 'GET',
    };

    try {
      const data = await makeRequest(config);

      setReport(data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getJobsReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshReport = () => getJobsReport();

  const nanToZero = (v, k) => {
    if (v && v[k]) {
      return v[k];
    }

    return 0;
  };

  const onJobClick = (consumerIds, message) => {
    clearTableFilters({
      tableName: 'consumers',
      initSortBy: 'createDate',
      initSortOrder: 'desc',
      initRowsPerPage: 25,
    });
    setSelectedConsumerIdsFilter({
      consumerIds: consumerIds.join(','),
      message,
    });
    navigate('/consumers');
  };

  const renderCell = (data, type, timeRange) => (
    <table>
      <tbody>
        {data && (
          <tr>
            <td className="bo-w-100">
              {nanToZero(data, 'SUCCESS') ? (
                <Button
                  className="bo-button-inline-link bo-inline-badge p-0 mb-1 me-1"
                  color="link"
                  onClick={() =>
                    onJobClick(
                      data.consumers.SUCCESS,
                      `Dashboard → Jobs Summary → Successful Jobs → ${type} → ${timeRange}`,
                    )
                  }
                >
                  <span>{nanToZero(data, 'SUCCESS')}</span>
                </Button>
              ) : null}
              {nanToZero(data, 'FAILED') ? (
                <Button
                  className="bo-button-inline-link bo-inline-badge p-0 mb-1"
                  color="link"
                  onClick={() =>
                    onJobClick(
                      data.consumers.FAILED,
                      `Dashboard → Jobs Summary → Failed Jobs → ${type} → ${timeRange}`,
                    )
                  }
                >
                  <span className="text-danger">({nanToZero(data, 'FAILED')} failed)</span>
                </Button>
              ) : null}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );

  const formatJobTimeRange = time => {
    const startTime = moment.utc(`${time}:00:00`).local();

    return `${startTime.format('HH:mm')}-${startTime.add(1, 'hours').format('HH:mm')}`;
  };

  return (
    <Card className="mt-3">
      <CardHeader className="card-header d-flex justify-content-between">
        Jobs Summary
        <Button className="bo-button-inline-link" color="link" onClick={() => refreshReport()}>
          Refresh
        </Button>
      </CardHeader>
      <CardBody className="bo-min-h-100">
        <Spinner visible={spinner} text="">
          {report && (
            <>
              <Row>
                <Col md={5}>Currently waiting for Assets Report:</Col>
                <Col md={7}>
                  <strong>{report.waiting}</strong>
                </Col>
              </Row>
              <Row>
                <Col md={5}>Currently in queue:</Col>
                <Col md={7}>
                  <strong>{report.queued}</strong>
                </Col>
              </Row>
              <Row>
                <Col md={5}>Currently running:</Col>
                <Col md={7}>
                  <strong>{report.running}</strong>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={5}>
                  <h6>Finished Jobs:</h6>
                </Col>
              </Row>
              <TableContainer className="mt-1">
                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left" />
                      <StyledTableCell align="left" className="bo-text-sm">
                        Initialization
                      </StyledTableCell>
                      <StyledTableCell align="left" className="bo-text-sm">
                        Incremental
                      </StyledTableCell>
                      <StyledTableCell align="left" className="bo-text-sm">
                        Re-predict
                      </StyledTableCell>
                      <StyledTableCell align="left" className="bo-text-sm">
                        Blind
                      </StyledTableCell>
                      <StyledTableCell align="left" className="bo-text-sm">
                        Default
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(report.finished)
                      .sort(([, v1], [, v2]) => v1.key - v2.key)
                      .map(([k, v]) => (
                        <TableRow key={v.key} hover tabIndex={-1}>
                          <StyledTableCell className="bo-w-120" align="left">
                            <strong>{formatJobTimeRange(k)}</strong>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {renderCell(v.INITIALIZATION, 'Initialization', formatJobTimeRange(k))}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {renderCell(v.INCREMENTAL, 'Incremental', formatJobTimeRange(k))}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {renderCell(v.RE_PREDICT, 'Repredict', formatJobTimeRange(k))}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {renderCell(v.BLIND, 'Blind', formatJobTimeRange(k))}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {renderCell(v.DEFAULT, 'Default', formatJobTimeRange(k))}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Spinner>
      </CardBody>
    </Card>
  );
}

export default DashboardJobsSummary;
