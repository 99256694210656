import { useEffect, useState } from 'react';
import { Button, Input, Label, Popover, PopoverBody } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';
import { PatternFormat } from 'react-number-format';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import { CONSUMER_JOB_STATUS, CONSUMER_NO_OFFER_REASON } from '../../../../../constants/consumers';
import { CircularProgress } from '@mui/material';

function NewObjectActiveOfferWidget({
  loading,
  jobStatus,
  setJobId,
  refreshConsumer,
  consumerId,
  openFunding,
  prediction,
}) {
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginConsumer, apiOriginFunding } = useEnv();

  const [isOpen, setIsOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const [amount, setAmount] = useState('');
  const [mfaCode, setMfaCode] = useState('');

  const sendMfa = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/mfa/send`,
      method: 'POST',
      data: JSON.stringify({ consumerId, mfaUseCase: 'ADVANCE' }),
    };

    try {
      const response = await makeRequest(config);

      setSpinner(false);
      toast.success(`MFA code has been sent by ${response.mfaChannel} to ${response.mfaMask}`);
    } catch (error) {
      setSpinner(false);
      toast.error(error.message);
    }
  };

  const createFunding = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setSpinner(true);

    const config = {
      token,
      url: `${apiOriginFunding}/create_manual`,
      method: 'POST',
      data: JSON.stringify({
        cosnumerId: consumerId,
        offerId: prediction.activeOffer ? prediction.activeOffer.offerId : null,
        requestedAmount: parseInt(amount, 10),
        textualReason: '',
        type: openFunding && openFunding.id ? 'TOPUP' : 'NEW',
        topupFundingId: null,
        mfaCode,
        consent: true,
      }),
    };

    try {
      const response = await makeRequest(config);

      if (response.jobId !== null) {
        setJobId(response.jobId);
      }

      toast.success(
        openFunding && openFunding.id
          ? 'Top-up created successfully'
          : 'Advance created successfully',
      );

      refreshConsumer(consumerId);
      setIsOpen(false);
      setAmount(prediction && prediction.activeOffer ? prediction.activeOffer.allAmounts[0] : '');
      setMfaCode('');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const getOfferText = () => {
    if (prediction && prediction.activeOffer && prediction.activeOffer.offerId && openFunding) {
      const { allAmounts } = prediction.activeOffer;

      return (
        <p className="bo-new-object-text-xl-bold">
          {`Up to $${allAmounts[allAmounts.length - 1]}`}
        </p>
      );
    }

    return (
      <div className="d-flex flex-column align-items-center">
        <p className="bo-new-object-text-xl-bold">Not Available</p>
        <p className="bo-new-object-text-sm">
          {prediction && prediction.noOfferReason
            ? CONSUMER_NO_OFFER_REASON[prediction.noOfferReason]
            : ''}
        </p>
      </div>
    );
  };

  const getJobStatusText = () => (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex align-items-center gap-2">
        <p className="bo-new-object-text-xl-bold">Please wait</p>
        <CircularProgress color="inherit" size={16} />
      </div>
      <p className="bo-new-object-text-sm">
        Job status: {CONSUMER_JOB_STATUS[jobStatus.status] || 'Unknown'}
      </p>
    </div>
  );

  const handleToggle = () => {
    setIsOpen(!isOpen);
    setAmount(prediction && prediction.activeOffer ? prediction.activeOffer.allAmounts[0] : '');
    setMfaCode('');
  };

  useEffect(() => {
    if (prediction && prediction.activeOffer) {
      setAmount(prediction.activeOffer.allAmounts[0]);
    }
  }, [prediction]);

  return (
    <>
      <div className="bo-border-container bg-light rounded w-100 p-0">
        <Spinner visible={loading.prediction && jobStatus.finished} size={36} borderRadius={5}>
          <div className="p-2">
            <div className="d-flex justify-content-between">
              <p className="bo-new-object-text-bold">Active Offer</p>
              <Button
                id="popover_active_offer"
                className="bo-new-object-inline-button"
                onClick={handleToggle}
              >
                {openFunding && openFunding.id ? 'Create Top-up' : 'Create Advance'}
              </Button>
            </div>
            {!jobStatus.finished ? (
              <div className="mt-2 d-flex justify-content-center">{getJobStatusText()}</div>
            ) : (
              <div className="mt-2 d-flex justify-content-center">{getOfferText()}</div>
            )}
          </div>
        </Spinner>
      </div>
      <Popover
        target="popover_active_offer"
        isOpen={isOpen}
        placement="bottom-start"
        toggle={handleToggle}
        offset={[0, 0]}
        fade={false}
        hideArrow
      >
        <PopoverBody className="bo-new-object-popover-form-menu">
          <Spinner visible={spinner} size={36}>
            <Label className="bo-new-object-popover-form-menu-label">Amount</Label>
            {prediction && prediction.activeOffer ? (
              <Input
                type="select"
                className="bo-new-object-popover-form-menu-input bo-w-150"
                value={amount}
                onChange={e => setAmount(e.target.value)}
              >
                {prediction.activeOffer.allAmounts.map(amt => (
                  <option key={amt} value={amt}>
                    ${amt}
                  </option>
                ))}
              </Input>
            ) : (
              <Input
                type="text"
                className="bo-new-object-popover-form-menu-input bo-w-150"
                value={amount}
                onChange={e => setAmount(e.target.value)}
              />
            )}
            <Label className="bo-new-object-popover-form-menu-label mt-2">MFA Code</Label>
            <div className="d-flex gap-3 bo-w-200">
              <PatternFormat
                className="bo-new-object-popover-form-menu-input bo-w-150"
                type="text"
                value={mfaCode || ''}
                customInput={Input}
                format="######"
                mask="_"
                onChange={e => setMfaCode(e.target.value)}
                readOnly={!amount}
              />
              <Button
                className="bo-new-object-inline-button text-decoration-none"
                disabled={spinner}
                onClick={sendMfa}
              >
                Send
              </Button>
            </div>
            <div className="mt-3 d-flex justify-content-between">
              <Button
                className="bo-new-object-button"
                onClick={createFunding}
                disabled={!amount || !mfaCode || mfaCode.replace('_', '').length !== 6}
              >
                Create
              </Button>
              <Button className="bo-new-object-button" onClick={handleToggle}>
                Close
              </Button>
            </div>
          </Spinner>
        </PopoverBody>
      </Popover>
    </>
  );
}

export default NewObjectActiveOfferWidget;
