import classnames from 'classnames';
import { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight, faWarning } from '@fortawesome/free-solid-svg-icons';
import { Button, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import { useEnv } from '../../../../context/env-context';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import LazyTabPanel from '../../../../components/LazyTabPanel';
import CollapsedPanel from '../../../../components/CollapsedPanel';
import NewObjectProfileActionSection from './sections/NewObjectProfleActionSection';
import NewObjectRiskIndicatorsTab from './tabs/NewObjectRiskIndicatorsTab';
import NewObjectPayrollTab from './tabs/NewObjectPayrollTab';

const PROFILE_PANEL_TABS = {
  risk_indicators: 'Risk Indicators',
  payroll: 'Payroll',
  transactions: 'Transactions',
  recurring_streams: 'Recurring Streams',
  variable_expenses: 'Variable Expenses',
  prediction: 'Prediction',
};

function NewObjectProfilePanel({
  collapsedPanels,
  expandChildPanel,
  profileRef,
  childGroupDefaultSizes,
  consumer,
  getConsumer,
  profileActiveTab,
  toggleProfileActiveTab,
}) {
  const { apiOriginMonitor } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [refreshTab, setRefreshTab] = useState('');
  const [profile, setProfile] = useState(null);

  const refresh = () => setRefreshTab(profileActiveTab);

  const getProfile = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/get_profile`,
      method: 'GET',
      params: { consumerId: consumer?.id },
    };

    try {
      const response = await makeRequest(config);

      setProfile(response);
    } catch (error) {
      toast.error(error.message);
    }
  }, [getAccessTokenSilently, apiOriginMonitor, consumer?.id]);

  const riskIndicatorsAboveThreshold =
    profile &&
    profile.riskIndicators &&
    Object.values(profile.riskIndicators).filter(
      x => x.threshold !== null && x.value !== null && x.value > x.threshold,
    ).length > 0;

  if (collapsedPanels.profile) {
    return (
      <CollapsedPanel
        side="down"
        onClick={() => expandChildPanel(profileRef, childGroupDefaultSizes[0])}
      />
    );
  }

  return (
    <div className="h-100 p-2 d-flex flex-column">
      <NewObjectProfileActionSection consumerId={consumer.id} />
      <div className="d-flex justify-content-between">
        <Nav className="bo-new-object-nav border-bottom-0" tabs>
          {Object.entries(PROFILE_PANEL_TABS).map(([key, value]) => (
            <NavItem key={key}>
              <NavLink
                className={`bo-new-object-nav-link ${classnames({
                  'bo-new-object-nav-link-active': profileActiveTab === key,
                })}`}
                onClick={() => toggleProfileActiveTab(key)}
              >
                {key === 'risk_indicators' && riskIndicatorsAboveThreshold && (
                  <FontAwesomeIcon icon={faWarning} size="1x" color="red" className="me-1" />
                )}
                {value}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <Button
          className="bo-new-object-inline-button me-2 d-flex align-items-center"
          onClick={refresh}
        >
          <FontAwesomeIcon icon={faArrowRotateRight} size="1x" color="#5946A4" />
        </Button>
      </div>
      <TabContent activeTab={profileActiveTab} className="bo-new-object-tabs">
        <LazyTabPanel activeTab={profileActiveTab} tabId="risk_indicators">
          <NewObjectRiskIndicatorsTab
            profile={profile}
            getProfile={getProfile}
            refreshTab={refreshTab}
            setRefreshTab={setRefreshTab}
          />
        </LazyTabPanel>
        <LazyTabPanel activeTab={profileActiveTab} tabId="payroll">
          <NewObjectPayrollTab
            consumer={consumer}
            getConsumer={getConsumer}
            profile={profile}
            getProfile={getProfile}
            refreshTab={refreshTab}
            setRefreshTab={setRefreshTab}
          />
        </LazyTabPanel>
        <LazyTabPanel activeTab={profileActiveTab} tabId="transactions" />
        <LazyTabPanel activeTab={profileActiveTab} tabId="recurring_streams" />
        <LazyTabPanel activeTab={profileActiveTab} tabId="variable_expenses" />
        <LazyTabPanel activeTab={profileActiveTab} tabId="prediction" />
      </TabContent>
    </div>
  );
}

export default NewObjectProfilePanel;
