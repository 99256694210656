import { Label } from 'reactstrap';

function NewObjectAchDwollaSection({ consumerDwollaCustomerUrl, fundingSource }) {
  return (
    <div>
      <Label className="bo-new-object-input-label">Dwolla Customer ID</Label>
      <p className="bo-new-object-text text-truncate">
        {consumerDwollaCustomerUrl && consumerDwollaCustomerUrl.split('/').slice(-1)}
      </p>
      <Label className="bo-new-object-input-label">Main Account Funding Source</Label>
      {fundingSource && fundingSource.id && (
        <div>
          <div className="d-flex align-items-center">
            <p className="bo-new-object-text-bold bo-min-w-100">ID</p>
            <p className="bo-new-object-text text-truncate">{fundingSource.id}</p>
          </div>
          <div className="d-flex align-items-center">
            <p className="bo-new-object-text-bold bo-min-w-100">Name</p>
            <p className="bo-new-object-text text-truncate">{fundingSource.name}</p>
          </div>
          <div className="d-flex align-items-center">
            <p className="bo-new-object-text-bold bo-min-w-100">Type</p>
            <p className="bo-new-object-text text-truncate">{fundingSource.type}</p>
          </div>
          <div className="d-flex align-items-center">
            <p className="bo-new-object-text-bold bo-min-w-100">Status</p>
            <p className="bo-new-object-text text-truncate text-capitalize">
              {fundingSource.status}
            </p>
          </div>
          <div className="d-flex align-items-center">
            <p className="bo-new-object-text-bold bo-min-w-100">Removed</p>
            <p className="bo-new-object-text text-truncate">
              {fundingSource.removed ? 'Yes' : 'No'}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewObjectAchDwollaSection;
