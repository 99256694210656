import { Container } from 'reactstrap';
import DefaultTransferSettings from '../../../../components/DefaultTransferSettings';
import DefaultSettingsInput from '../../../../components/DefaultSettingsInput';
import { memo } from 'react';

function AggregatorSettings({
  aggregatorDefaultFreeCreditTransfers,
  aggregatorDefaultFreeDebitTransfers,
  aggregatorDefaultTransferFee,
  aggregatorDefaultBpInitQuota,
  aggregatorDefaultBpMonthlyQuota,
  aggregatorDefaultBpCap,
  changeValue,
}) {
  const onButtonClick = (fieldName, value) =>
    changeValue({ target: { name: fieldName, value: value ? null : '0' } });

  return (
    <Container>
      <DefaultTransferSettings
        defaultFreeCreditTransfers={aggregatorDefaultFreeCreditTransfers}
        defaultFreeDebitTransfers={aggregatorDefaultFreeDebitTransfers}
        defaultTransferFee={aggregatorDefaultTransferFee}
        changeValue={changeValue}
        labelClass="bo-w-300"
      />
      <DefaultSettingsInput
        fieldName="defaultBpInitQuota"
        fieldValue={aggregatorDefaultBpInitQuota}
        labelText="Default Initial BridgePoints™ Quota:"
        labelClass="bo-w-300"
        changeValue={changeValue}
        onButtonClick={onButtonClick}
      />
      <DefaultSettingsInput
        fieldName="defaultBpMonthlyQuota"
        fieldValue={aggregatorDefaultBpMonthlyQuota}
        containerClass="mt-2"
        labelText="Default Monthly BridgePoints™ Quota:"
        labelClass="bo-w-300"
        changeValue={changeValue}
        onButtonClick={onButtonClick}
      />
      <DefaultSettingsInput
        fieldName="defaultBpCap"
        fieldValue={aggregatorDefaultBpCap}
        containerClass="my-2"
        labelText="Default BridgePoints™ Cap:"
        labelClass="bo-w-300"
        changeValue={changeValue}
        onButtonClick={onButtonClick}
      />
    </Container>
  );
}

export default memo(AggregatorSettings);
