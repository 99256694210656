import { useCallback, useEffect, useState } from 'react';
import { INSTITUTION_CATEGORY_BADGE } from '../../../../constants/consumers';
// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
// API
import { useEnv } from '../../../../context/env-context';
import { useAuth } from '../../../../hooks/useAuth';
import { toast } from 'react-toastify';
import { makeRequest } from '../../../../utils/makeRequest';
// components
import { Button } from 'reactstrap';
import { Spinner } from '../../../../components/Spinner';
import CollapsedPanel from '../../../../components/CollapsedPanel';
import NewObjectAccordion from '../shared/NewObjectAccordion';
import NewObjectPopoverBankStatusForm from './forms/NewObjectPopoverBankStatusForm';
// widgets
import NewObjectBalancesWidget from './widgets/NewObjectBalancesWidget';
// sections
import NewObjectBankAccountsSection from './sections/NewObjectBankAccountsSection';
import NewObjectAccountOwnersSection from './sections/NewObjectAccountOwnersSection';
import NewObjectAchDwollaSection from './sections/NewObjectAchDwollaSection';
import NewObjectKycSection from './sections/NewObjectKycSection';
import NewObjectCardsSection from './sections/NewObjectCardsSection';

function NewObjectBankPanel({
  collapsedPanels,
  expandParentPanel,
  consumer,
  setConsumer,
  panelDataRefresh,
  setPanelDataRefresh,
}) {
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginConsumer, apiOriginFunding, apiOriginMonitor } = useEnv();

  const [spinner, setSpinner] = useState(false);
  const [loading, setLoading] = useState({ balances: false });

  const [balances, setBalances] = useState({ balances: [], date: null });
  const [institution, setInstitution] = useState(null);
  const [fundingSource, setFundingSource] = useState(null);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [bankAccountOwners, setBankAccountOwners] = useState([]);
  const [astraUser, setAstraUser] = useState(null);

  const getRequest = useCallback(
    async (url, setFunction) => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url,
        method: 'GET',
        params: { consumerId: consumer.id },
      };

      try {
        const response = await makeRequest(config);

        setFunction(response);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [consumer?.id, getAccessTokenSilently],
  );

  const getInstitution = useCallback(
    () => getRequest(`${apiOriginConsumer}/getInstitution`, setInstitution),
    [apiOriginConsumer, getRequest],
  );

  const getFundingSource = useCallback(
    () => getRequest(`${apiOriginFunding}/get_funding_source`, setFundingSource),
    [apiOriginFunding, getRequest],
  );

  const getBankAccounts = useCallback(
    () => getRequest(`${apiOriginConsumer}/get_all_accounts`, setBankAccounts),
    [apiOriginConsumer, getRequest],
  );

  const getBankAccountOwners = useCallback(
    () => getRequest(`${apiOriginConsumer}/getOwners`, setBankAccountOwners),

    [apiOriginConsumer, getRequest],
  );

  const getAstraUser = useCallback(
    () => getRequest(`${apiOriginConsumer}/astra/user`, setAstraUser),
    [apiOriginConsumer, getRequest],
  );

  const getBalances = useCallback(async () => {
    setLoading({ balances: true });
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/balances`,
      method: 'GET',
      params: { consumerId: consumer.id },
    };

    try {
      const response = await makeRequest(config);

      setBalances(response);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading({ balances: false });
    }
  }, [apiOriginMonitor, consumer?.id, getAccessTokenSilently]);

  useEffect(() => {
    if (panelDataRefresh.balances) {
      getBalances();
      setPanelDataRefresh(prev => ({ ...prev, balances: false }));
    }
  }, [getBalances, panelDataRefresh, setPanelDataRefresh]);

  const refreshBankData = useCallback(async () => {
    await Promise.all([
      getInstitution(),
      getFundingSource(),
      getBankAccounts(),
      getBankAccountOwners(),
      getAstraUser(),
    ]);
    getBalances();
  }, [
    getInstitution,
    getFundingSource,
    getBankAccounts,
    getBankAccountOwners,
    getAstraUser,
    getBalances,
  ]);

  const refresh = async () => {
    setSpinner(true);
    await refreshBankData(consumer.id);
    setSpinner(false);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disconnectBankAccount = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);
      try {
        const token = await getAccessTokenSilently();

        if (!token) {
          return;
        }

        const config = {
          token,
          url: `${apiOriginConsumer}/link/disconnect`,
          method: 'POST',
          data: JSON.stringify({ consumerId: consumer.id }),
        };

        await makeRequest(config);
        await refreshBankData(consumer.id);

        setConsumer(prevConsumer => ({ ...prevConsumer, bankAccountStatus: 'NOT_CONNECTED' }));
        toast.success('Consumer successfully disconnected');
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  if (collapsedPanels.bank) {
    return <CollapsedPanel side="left" onClick={() => expandParentPanel(2)} />;
  }

  return (
    <Spinner visible={spinner}>
      <div className="h-100">
        <div className="py-2 ps-2 pe-3">
          <div className="d-flex align-items-center justify-content-between gap-2">
            <p className="bo-new-object-text-xl-bold pb-1 text-truncate">
              {institution?.name || '----'}
            </p>
            <div className="d-flex align-items-center">
              <NewObjectPopoverBankStatusForm
                consumerId={consumer.id}
                consumerBankAccountStatus={consumer.bankAccountStatus}
                setConsumer={setConsumer}
              />
              <Button className="bo-new-object-base-button pb-1" onClick={refresh}>
                <FontAwesomeIcon icon={faArrowRotateRight} size="sm" color="#5946A4" />
              </Button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p
              className={`bo-new-object-text ${
                institution &&
                institution.category !== 'A' &&
                institution.category !== 'B' &&
                institution.category !== 'C' &&
                'text-danger'
              }`}
            >
              {INSTITUTION_CATEGORY_BADGE[institution?.category]
                ? `Category ${institution?.category}`
                : '----'}
            </p>
            <Button
              className="bo-new-object-inline-button"
              onClick={disconnectBankAccount}
              disabled={
                consumer.bankAccountStatus === 'NOT_CONNECTED' ||
                consumer.bankAccountStatus === 'NO_TRANSFERS'
              }
            >
              Disconnect
            </Button>
          </div>
        </div>
        {consumer.bankAccountStatus !== 'NOT_CONNECTED' &&
          consumer.bankAccountStatus !== 'NO_TRANSFERS' && (
            <div className="py-2 ps-2 pe-3">
              <NewObjectBalancesWidget
                loading={loading.balances}
                balances={balances}
                bankAccounts={bankAccounts}
                consumerBankAccountStatus={consumer.bankAccountStatus}
              />
            </div>
          )}
        <NewObjectAccordion title="Bank Accounts">
          <NewObjectBankAccountsSection
            consumerId={consumer.id}
            bankAccounts={bankAccounts}
            setBankAccounts={setBankAccounts}
          />
        </NewObjectAccordion>
        <NewObjectAccordion title="Main Account Owners">
          <NewObjectAccountOwnersSection bankAccountOwners={bankAccountOwners} />
        </NewObjectAccordion>
        <NewObjectAccordion title="KYC">
          <NewObjectKycSection
            consumerId={consumer.id}
            consumerKycStatus={consumer.kycStatus}
            consumerAmlStatus={consumer.amlStatus}
            consumerFraudIndicator={consumer.fraudIndicator}
            setPanelDataRefresh={setPanelDataRefresh}
          />
        </NewObjectAccordion>
        <NewObjectAccordion title="ACH (Dwolla)">
          <NewObjectAchDwollaSection
            consumerDwollaCustomerUrl={consumer.dwollaCustomerUrl}
            fundingSource={fundingSource}
          />
        </NewObjectAccordion>
        <NewObjectAccordion title="Cards (Astra)" bottomBorder>
          <NewObjectCardsSection
            astraUser={astraUser}
            setSpinner={setSpinner}
            getAstraUser={getAstraUser}
            consumerId={consumer.id}
            consumerAddressLine1={consumer.addressLine1}
            consumerAddressLine2={consumer.addressLine2}
            consumerCity={consumer.city}
            consumerState={consumer.state}
            consumerZip={consumer.zip}
          />
        </NewObjectAccordion>
      </div>
    </Spinner>
  );
}

export default NewObjectBankPanel;
