import { toast } from 'react-toastify';
import { Container } from 'reactstrap';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useEnv } from '../../context/env-context';
import { makeRequest } from '../../utils/makeRequest';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../components/Loading';
import { Spinner } from '../../components/Spinner';
import { REPORTS_DISPLAY_NAMES } from '../../constants/reports';
import ReportRepaymentAnalytics from './components/report-types/ReportRepaymentAnalytics';
import ReportBilling from './components/report-types/ReportBilling';
import ReportConsumerEconomics from './components/report-types/ReportConsumerEconomics';
import ReportConsumerAnalytics from './components/report-types/ReportConsumerAnalytics';
import ReportConsumerHubSpot from './components/report-types/ReportConsumerHubSpot';
import ReportAdvancesAnalytics from './components/report-types/ReportAdvancesAnalytics';
import ReportTransfersAnalytics from './components/report-types/ReportTransfersAnalytics';
import ReportSignupFunnel from './components/report-types/ReportSignupFunnel';
import ReportGenericSignup from './components/report-types/ReportGenericSignup';
import ReportRiskIndicators from './components/report-types/ReportRiskIndicators';
import ReportDetailedSignupFunnel from './components/report-types/ReportDetailedSignupFunnel';

const REPORT_COMPONENTS = {
  REPAYMENTS_ANALYTICS: ReportRepaymentAnalytics,
  BILLING: ReportBilling,
  CONSUMER_ECONOMICS: ReportConsumerEconomics,
  CONSUMER_ANALYTICS: ReportConsumerAnalytics,
  CONSUMER_HUBSPOT: ReportConsumerHubSpot,
  ADVANCES_ANALYTICS: ReportAdvancesAnalytics,
  TRANSFERS_ANALYTICS: ReportTransfersAnalytics,
  SIGNUP_FUNNEL: ReportSignupFunnel,
  GENERIC_SIGNUP: ReportGenericSignup,
  RISK_INDICATORS: ReportRiskIndicators,
  DETAILED_SIGNUP_FUNNEL: ReportDetailedSignupFunnel,
};

function Report() {
  const params = useParams();
  const { apiOriginReports } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const { id: reportId } = params;

  const [spinner, setSpinner] = useState(false);
  const [report, setReport] = useState([]);
  const [reportMetadata, setReportMetadata] = useState(null);

  const getReport = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setSpinner(true);

    const config = {
      token,
      url: `${apiOriginReports}/get_data`,
      method: 'GET',
      params: { reportId },
    };

    try {
      const response = await makeRequest(config);

      setReport(response?.data);
      setReportMetadata(response?.metadata);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderReport = () => {
    if (
      !reportId ||
      !reportMetadata ||
      !reportMetadata.type ||
      !REPORTS_DISPLAY_NAMES[reportMetadata.type]
    ) {
      return null;
    }

    const ReportComponent = REPORT_COMPONENTS[reportMetadata.type];

    return (
      <ReportComponent
        reportTitle={REPORTS_DISPLAY_NAMES[reportMetadata.type]}
        reportDetails={reportMetadata.details}
        reportId={reportId}
        reportFileName={reportMetadata.fileName}
        setSpinner={setSpinner}
        report={report}
      />
    );
  };

  return (
    <Spinner visible={spinner}>
      <Container className="mb-4">{renderReport()}</Container>
    </Spinner>
  );
}

export default withAuthenticationRequired(Report, { onRedirecting: () => <Loading /> });
