import { toast } from 'react-toastify';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import NewObjectSelectInput from '../../shared/NewObjectSelectInput';

function NewObjectKycSection({
  consumerId,
  consumerKycStatus,
  consumerAmlStatus,
  consumerFraudIndicator,
  setPanelDataRefresh,
}) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const setConsumerStatus = async (statusType, status) => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/set_consumer_status`,
      method: 'POST',
      data: JSON.stringify({ consumerId, [statusType]: status }),
    };

    try {
      await makeRequest(config);

      setPanelDataRefresh(prev => ({ ...prev, consumer: true }));
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div>
      <NewObjectSelectInput
        label="KYC Status"
        initialValue={consumerKycStatus}
        options={[
          { key: 'NEW_CONSUMER', value: 'NEW_CONSUMER', name: 'New Consumer' },
          { key: 'PASSED', value: 'PASSED', name: 'Passed' },
          {
            key: 'PASSED_MANUAL_OVERRIDE',
            value: 'PASSED_MANUAL_OVERRIDE',
            name: 'Passed - Manual Override',
          },
          {
            key: 'PASSED_ID_VERIFICATION',
            value: 'PASSED_ID_VERIFICATION',
            name: 'Passed - ID Verification',
          },
          {
            key: 'FAILED_AUTOMATIC_VERIFICATION',
            value: 'FAILED_AUTOMATIC_VERIFICATION',
            name: 'Failed - Automatic Verification',
          },
          {
            key: 'ID_VERIFICATION_SENT',
            value: 'ID_VERIFICATION_SENT',
            name: 'ID Verification Sent',
          },
          {
            key: 'FAILED_MANUAL_REVIEW',
            value: 'FAILED_MANUAL_REVIEW',
            name: 'Failed - Manual Review',
          },
          { key: 'REJECTED', value: 'REJECTED', name: 'Rejected' },
        ]}
        onSave={value => setConsumerStatus('kycStatus', value)}
      />
      <NewObjectSelectInput
        label="AML Status"
        initialValue={consumerAmlStatus}
        labelClassName="mt-3"
        options={[
          { key: 'NEW_CONSUMER', value: 'NEW_CONSUMER', name: 'New Consumer' },
          { key: 'CLEARED', value: 'CLEARED', name: 'Cleared' },
          {
            key: 'CLEARED_MANUAL_OVERRIDE',
            value: 'CLEARED_MANUAL_OVERRIDE',
            name: 'Cleared - Manual Override',
          },
          {
            key: 'PENDING_REVIEW',
            value: 'PENDING_REVIEW',
            name: 'Pending Review',
          },
          {
            key: 'REJECTED',
            value: 'REJECTED',
            name: 'Rejected',
          },
        ]}
        onSave={value => setConsumerStatus('amlStatus', value)}
      />
      <NewObjectSelectInput
        label="Fraud Indicator"
        initialValue={consumerFraudIndicator}
        labelClassName="mt-3"
        options={[
          { key: 'CLEAR', value: 'CLEAR', name: 'Clear' },
          {
            key: 'CLEARED_MANUAL_OVERRIDE',
            value: 'CLEARED_MANUAL_OVERRIDE',
            name: 'Cleared - Manual override',
          },
          {
            key: 'FRAUD_SUSPECTED_WAITING_FOR_REVIEW',
            value: 'FRAUD_SUSPECTED_WAITING_FOR_REVIEW',
            name: '  Fraud Suspected - Waiting for review',
          },
          {
            key: 'FRAUD_SUSPECTED_UNDER_REVIEW',
            value: 'FRAUD_SUSPECTED_UNDER_REVIEW',
            name: 'Fraud Suspected - Under review',
          },
          { key: 'BLOCKED_FOR_FRAUD', value: 'BLOCKED_FOR_FRAUD', name: 'Blocked for fraud' },
        ]}
        onSave={value => setConsumerStatus('fraudIndicator', value)}
      />
    </div>
  );
}

export default NewObjectKycSection;
