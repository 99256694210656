import { TabContent, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import AggregatorSignup from './tabs/AggregatorSignup';
import { useState } from 'react';
import classnames from 'classnames';
import AggregatorSettings from './tabs/AggregatorSettings';
import LazyTabPanel from '../../../components/LazyTabPanel';

function AggregatorTabController({
  aggregator,
  setSpinner,
  changeChecked,
  changeValue,
  uniformUrl,
  openModal,
}) {
  const [activeTab, setActiveTab] = useState('signup');

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Row>
      <Col md={2} />
      <Col md={10}>
        <Nav className="bo-pointer mt-5" tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === 'signup',
              })}
              onClick={() => toggle('signup')}
            >
              Signup
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === 'settings',
              })}
              onClick={() => toggle('settings')}
            >
              Settings
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="bo-tabs">
          <LazyTabPanel activeTab={activeTab} tabId="signup">
            <AggregatorSignup
              aggregatorId={aggregator.id}
              aggregatorUseEmployeeIdProspects={aggregator.useEmployeeIdProspects}
              aggregatorHideEmployeeIdProspects={aggregator.hideEmployeeIdProspects}
              aggregatorAllowAnonymousSignups={aggregator.allowAnonymousSignups}
              aggregatorUseEmployeeIdAnonymous={aggregator.useEmployeeIdAnonymous}
              aggregatorMaxAllowedSignups={aggregator.maxAllowedSignups}
              setSpinner={setSpinner}
              changeChecked={changeChecked}
              changeValue={changeValue}
              uniformUrl={uniformUrl}
              openModal={openModal}
            />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeTab} tabId="settings">
            <AggregatorSettings
              aggregatorDefaultFreeCreditTransfers={aggregator.defaultFreeCreditTransfers}
              aggregatorDefaultFreeDebitTransfers={aggregator.defaultFreeDebitTransfers}
              aggregatorDefaultTransferFee={aggregator.defaultTransferFee}
              aggregatorDefaultBpInitQuota={aggregator.defaultBpInitQuota}
              aggregatorDefaultBpMonthlyQuota={aggregator.defaultBpMonthlyQuota}
              aggregatorDefaultBpCap={aggregator.defaultBpCap}
              changeValue={changeValue}
            />
          </LazyTabPanel>
        </TabContent>
      </Col>
    </Row>
  );
}

export default AggregatorTabController;
