import * as ExcelJS from 'exceljs';
import { useCallback, useMemo } from 'react';
import { applyExcelFormats, exportExcelFile, formatExcelDate } from '../../../../utils/excel';
import ReportActionRow from '../ReportActionRow';
import ReportTable from '../ReportTable';

function ReportConsumerAnalytics({
  reportTitle,
  reportDetails,
  reportId,
  reportFileName,
  setSpinner,
  report,
}) {
  const tableColumns = useMemo(
    () => ({
      id: {
        header: 'Id',
        width: 12,
        style: { alignment: { horizontal: 'right' } },
      },
      firstName: {
        header: 'First Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      lastName: {
        header: 'Last Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      registrationTime: {
        header: 'Registration Time',
        width: 22,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd hh:mm:ss' },
      },
      aggregatorId: {
        header: 'Aggregator Id',
        width: 16,
        style: { alignment: { horizontal: 'right' } },
      },
      aggregatorName: {
        header: 'Aggregator Name',
        width: 22,
        style: { alignment: { horizontal: 'left' } },
      },
      status: {
        header: 'Status',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      bankName: {
        header: 'Bank Name',
        width: 30,
        style: { alignment: { horizontal: 'left' } },
      },
      bankAccountStatus: {
        header: 'Bank Account Status',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      dateOfBirth: {
        header: 'Date of Birth',
        width: 18,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd' },
      },
      devicePlatform: {
        header: 'Mobile OS',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      firstLogin: {
        header: 'First Login',
        width: 22,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd hh:mm:ss' },
      },
      lastLogin: {
        header: 'Last Login',
        width: 22,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd hh:mm:ss' },
      },
      logins: {
        header: 'Logins',
        width: 16,
        style: { alignment: { horizontal: 'right' } },
      },
      signupLink: {
        header: 'Signup Link',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      profileDataTimestamp: {
        header: 'Profile Date/Time',
        width: 22,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd hh:mm:ss' },
      },
      profileValidity: {
        header: 'Profile Status',
        width: 25,
        style: { alignment: { horizontal: 'left' } },
      },
      payrollFequency: {
        header: 'Payroll Frequency',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      nextPayroll: {
        header: 'Next Payroll',
        width: 18,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd' },
      },
      currentBalance: {
        header: 'Current Balance',
        width: 18,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      availableBalance: {
        header: 'Available Balance',
        width: 22,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
    }),
    [],
  );

  const exportExcel = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Consumer Analytics', {
      views: [{ state: 'frozen', ySplit: 1 }],
    });

    const wsColumns = Object.entries(tableColumns).map(([key, value]) => ({ ...value, key }));

    worksheet.columns = wsColumns;

    const dateFormattedReport = report.map(r => ({
      ...r,
      registrationTime: r.registrationTime ? formatExcelDate(new Date(r.registrationTime)) : null,
      dateOfBirth: r.dateOfBirth ? formatExcelDate(new Date(r.dateOfBirth)) : null,
      firstLogin: r.firstLogin ? formatExcelDate(new Date(r.firstLogin)) : null,
      lastLogin: r.lastLogin ? formatExcelDate(new Date(r.lastLogin)) : null,
      nextPayroll: r.nextPayroll ? formatExcelDate(new Date(r.nextPayroll)) : null,
    }));

    worksheet.addRows(dateFormattedReport);

    const headerRow = worksheet.getRow(1);

    headerRow.alignment = { horizontal: 'left' };
    headerRow.font = { bold: true };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
    };

    await exportExcelFile(workbook, `${reportFileName}.xlsx`);
  }, [report, reportFileName, tableColumns]);

  const formattedReport = useMemo(() => {
    if (report.length > 0) {
      return report.map(r =>
        Object.entries(r).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]:
              value !== null ? applyExcelFormats(value, tableColumns[key]?.style?.numFmt) : null,
          }),
          {},
        ),
      );
    }

    return [];
  }, [report, tableColumns]);

  return (
    <>
      <ReportActionRow
        reportTitle={reportTitle}
        reportDetails={reportDetails}
        reportId={reportId}
        setSpinner={setSpinner}
        exportExcel={exportExcel}
      />
      <ReportTable
        report={formattedReport}
        tableColumns={tableColumns}
        headerHeight={36}
        rowKey="id"
      />
    </>
  );
}

export default ReportConsumerAnalytics;
