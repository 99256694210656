import { useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import CollapsedPanel from '../../../../components/CollapsedPanel';
import NewObjectProfilePanel from './NewObjectProfilePanel';
import NewObjectActivityPanel from './NewObjectActivityPanel';

function NewObjectProfileActivityPanel({
  collapsedPanels,
  setCollapsedPanels,
  expandParentPanel,
  expandChildPanel,
  profileRef,
  activityRef,
  childGroupCollapsedSize,
  childGroupMinSizes,
  childGroupDefaultSizes,
  consumer,
  getConsumer,
  setPanelDataRefresh,
}) {
  const [profileActiveTab, setProfileActiveTab] = useState('risk_indicators');
  const [activityActiveTab, setActivityActiveTab] = useState('application_activity');

  const toggleProfileActiveTab = tab => {
    if (profileActiveTab !== tab) {
      setProfileActiveTab(tab);
    }
  };

  const toggleActivityActiveTab = tab => {
    if (profileActiveTab !== tab) {
      setActivityActiveTab(tab);
    }
  };

  if (collapsedPanels.profile_activity) {
    return (
      <CollapsedPanel
        side={collapsedPanels.details ? 'right' : 'left'}
        onClick={() => expandParentPanel(1)}
      />
    );
  }

  return (
    <PanelGroup direction="vertical">
      <Panel
        ref={profileRef}
        collapsible
        collapsedSize={childGroupCollapsedSize}
        minSize={childGroupMinSizes[0]}
        defaultSize={childGroupDefaultSizes[0]}
        onCollapse={() => setCollapsedPanels(prev => ({ ...prev, profile: true }))}
        onExpand={() => setCollapsedPanels(prev => ({ ...prev, profile: false }))}
      >
        <NewObjectProfilePanel
          collapsedPanels={collapsedPanels}
          expandChildPanel={expandChildPanel}
          profileRef={profileRef}
          childGroupDefaultSizes={childGroupDefaultSizes}
          consumer={consumer}
          getConsumer={getConsumer}
          profileActiveTab={profileActiveTab}
          toggleProfileActiveTab={toggleProfileActiveTab}
        />
      </Panel>
      <PanelResizeHandle className="bo-new-object-resize-handle-horizontal" />
      <Panel
        ref={activityRef}
        collapsible
        collapsedSize={childGroupCollapsedSize}
        minSize={childGroupMinSizes[1]}
        defaultSize={childGroupDefaultSizes[1]}
        onCollapse={() => setCollapsedPanels(prev => ({ ...prev, activity: true }))}
        onExpand={() => setCollapsedPanels(prev => ({ ...prev, activity: false }))}
      >
        <NewObjectActivityPanel
          collapsedPanels={collapsedPanels}
          expandChildPanel={expandChildPanel}
          activityRef={activityRef}
          childGroupDefaultSizes={childGroupDefaultSizes}
          consumer={consumer}
          getConsumer={getConsumer}
          activityActiveTab={activityActiveTab}
          toggleActivityActiveTab={toggleActivityActiveTab}
          setPanelDataRefresh={setPanelDataRefresh}
        />
      </Panel>
    </PanelGroup>
  );
}

export default NewObjectProfileActivityPanel;
