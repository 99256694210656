import { memo } from 'react';
import { Col, Label, Badge } from 'reactstrap';

function FundingPayrollDelayedForm({ fundingIsDelayedAfterPayrollCluster }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Payroll delayed:</Label>
      </Col>
      <Col md={1} className="bo-same-line">
        {fundingIsDelayedAfterPayrollCluster ? (
          <Badge className="bo-badge" color="danger">
            Yes
          </Badge>
        ) : (
          <Badge className="bo-badge" color="success">
            No
          </Badge>
        )}
      </Col>
    </>
  );
}
export default memo(FundingPayrollDelayedForm);
