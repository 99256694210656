import { toast } from 'react-toastify';
import { Container } from 'reactstrap';
import { useState, useEffect, useMemo } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useAuth } from '../../hooks/useAuth';
import { Spinner } from '../../components/Spinner';
import { useEnv } from '../../context/env-context';
import { makeRequest } from '../../utils/makeRequest';
import { useTableContext } from '../../context/table-context';
import FundingsFormController from './components/FundingsFormController';
import FundingsTableContainer from './components/FundingsTableContainer';
import TablePaginationActions from '../../components/TablePaginationActions';
import Loading from '../../components/Loading';

function Fundings() {
  const { apiOriginFunding } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [todayRepaymentTrials, setTodayRepaymentTrials] = useState(null);

  const {
    selectedAggregatorFilter,
    setSelectedAggregatorFilter,
    selectedConsumerFilter,
    setSelectedConsumerFilter,
    selectedFundingIdsFilter,
    setSelectedFundingIdsFilter,
    getTable,
    updateTable,
    sortHandler,
    handleChangePage,
    handleChangeRowsPerPage,
    changeStatusFilter,
    changeFlagsFilter,
    searchKey,
    changeModeFilter,
  } = useTableContext();

  const table = getTable({
    tableName: 'fundings',
    initSortBy: 'id',
    initSortOrder: 'desc',
    initRowsPerPage: 25,
    initStatusFilter: 'OPEN,DUE,CLOSING',
  });

  const {
    sortBy,
    sortOrder,
    page,
    rowsPerPage,
    totalRows,
    rows,
    statusFilter,
    flagsFilter,
    search,
    modeFilter,
  } = table;

  const changeAggregatorFilter = e => {
    setSelectedAggregatorFilter(e.target.value);
    updateTable({
      tableName: 'fundings',
      newTable: {
        ...table,
        page: 0,
      },
    });
  };

  const clearConsumerFilter = () => {
    setSelectedConsumerFilter({
      consumerId: null,
      consumerFirstName: null,
      consumerLastName: null,
      message: null,
    });
    updateTable({
      tableName: 'fundings',
      newTable: {
        ...table,
        page: 0,
        statusFilter: 'OPEN,DUE,CLOSING',
      },
    });
  };

  const clearFundingIdsFilter = () => {
    setSelectedFundingIdsFilter({ fundingIds: null, message: null });
    updateTable({
      tableName: 'fundings',
      newTable: {
        ...table,
        page: 0,
        statusFilter: 'OPEN,DUE,CLOSING',
      },
    });
  };

  const getTodayRepaymentTrials = async fundingIds => {
    setTodayRepaymentTrials(null);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/today_repayment_trials`,
      method: 'GET',
      params: {
        fundingIds,
      },
    };

    try {
      const data = await makeRequest(config);

      setTodayRepaymentTrials(data);
    } catch (error) {
      setTodayRepaymentTrials({});
      console.error(error.message);
    }
  };

  const getData = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/get`,
      method: 'GET',
      params: {
        sortBy,
        sortOrder,
        statusFilter,
        flagsFilter,
        aggregatorFilter: selectedAggregatorFilter,
        consumerFilter: selectedConsumerFilter ? selectedConsumerFilter.consumerId : null,
        searchText: search,
        page,
        perPage: rowsPerPage,
        fundingIds: selectedFundingIdsFilter.fundingIds,
        repaymentModeFilter: modeFilter || null,
      },
    };

    const data = await makeRequest(config);

    return data;
  };

  const getTotalRows = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/count`,
      method: 'GET',
      params: {
        statusFilter,
        flagsFilter,
        aggregatorFilter: selectedAggregatorFilter,
        consumerFilter: selectedConsumerFilter ? selectedConsumerFilter.consumerId : null,
        searchText: search,
        fundingIds: selectedFundingIdsFilter.fundingIds,
        repaymentModeFilter: modeFilter || null,
      },
    };
    const data = await makeRequest(config);

    return data.count;
  };

  const getAll = async () => {
    setSpinner(true);
    try {
      const data = await getData();
      const total = await getTotalRows();

      if (data && total !== null) {
        updateTable({
          tableName: 'fundings',
          newTable: {
            ...table,
            rows: data,
            totalRows: total,
          },
        });
      }
    } catch (error) {
      toast.error(`Faled getting data: ${error.message}`);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    statusFilter,
    flagsFilter,
    selectedAggregatorFilter,
    selectedConsumerFilter,
    selectedFundingIdsFilter,
    search,
    modeFilter,
    page,
    rowsPerPage,
    sortBy,
    sortOrder,
  ]);

  useEffect(() => {
    if (rows && rows.length > 0) {
      getTodayRepaymentTrials(rows.map(x => x.id).join(','));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const transformedRows = useMemo(() => {
    if (todayRepaymentTrials === null) {
      return rows.map(row => ({ ...row, todayRepaymentTrials: 'Loading...' }));
    }

    return rows.map(row => ({ ...row, todayRepaymentTrials: todayRepaymentTrials[row.id] }));
  }, [rows, todayRepaymentTrials]);

  return (
    <Container className="mb-5">
      <FundingsFormController
        spinner={spinner}
        selectedAggregatorFilter={selectedAggregatorFilter}
        changeAggregatorFilter={changeAggregatorFilter}
        selectedConsumerFilter={selectedConsumerFilter}
        clearConsumerFilter={clearConsumerFilter}
        selectedFundingIdsFilter={selectedFundingIdsFilter}
        clearFundingIdsFilter={clearFundingIdsFilter}
        statusFilter={statusFilter}
        changeStatusFilter={changeStatusFilter}
        flagsFilter={flagsFilter}
        changeFlagsFilter={changeFlagsFilter}
        search={search}
        searchKey={searchKey}
        modeFilter={modeFilter}
        changeModeFilter={changeModeFilter}
      />
      <Spinner visible={spinner} text="">
        <FundingsTableContainer
          sortOrder={sortOrder}
          sortBy={sortBy}
          sortHandler={sortHandler}
          rows={transformedRows}
          totalRows={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          TablePaginationActions={TablePaginationActions}
        />
      </Spinner>
    </Container>
  );
}

export default withAuthenticationRequired(Fundings, { onRedirecting: () => <Loading /> });
