import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';
import { Button, TabContent } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';
import LazyTabPanel from '../../../../../components/LazyTabPanel';
import NewObjectGeneralInfoSubTab from '../sub-tabs/payroll/NewObjectGeneralInfoSubTab';
import NewObjectAllCyclesSubTab from '../sub-tabs/payroll/NewObjectAllCyclesSubTab';
import NewObjectPaydaySubTab from '../sub-tabs/payroll/NewObjectPaydaySubTab';

const PAYROL_SUB_TABS = {
  general_info: 'General Info',
  all_cycles: 'All Cycles',
  payday_distribution: 'Payday Distribution',
};

function NewObjectPayrollTab({
  consumer,
  getConsumer,
  profile,
  getProfile,
  refreshTab,
  setRefreshTab,
}) {
  const [activeSubTab, setActiveSubTab] = useState('general_info');

  const [spinner, setSpinner] = useState(false);

  const toggleActiveSubTab = tab => {
    if (activeSubTab !== tab) {
      setActiveSubTab(tab);
    }
  };

  const getPayroll = async () => {
    setSpinner(true);
    await getProfile();
    setSpinner(false);
  };

  useEffect(() => {
    if (!profile) {
      getPayroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshTab === 'payroll') {
      getPayroll();
      setRefreshTab('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTab]);

  return (
    <div className="h-100 w-100 d-flex flex-column">
      <Spinner
        visible={spinner}
        wrapperStyle={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
      >
        <div className="p-2 d-flex align-items-center gap-5 border-bottom">
          {Object.entries(PAYROL_SUB_TABS).map(([key, value]) => (
            <Button
              key={key}
              className={`bo-new-object-sub-link ${classNames({
                'bo-new-object-sub-link-active': activeSubTab === key,
              })}`}
              onClick={() => toggleActiveSubTab(key)}
            >
              {value}
            </Button>
          ))}
        </div>
        <TabContent activeTab={activeSubTab} className="d-flex flex-column flex-grow-1">
          <LazyTabPanel activeTab={activeSubTab} tabId="general_info">
            <NewObjectGeneralInfoSubTab
              consumerId={consumer.id}
              consumerPayrollStatus={consumer.payrollStatus}
              consumerLockPayrollDay={consumer.lockPayrollDay}
              consumerPayrollFrequency={consumer.payrollFrequency}
              consumerPayrollDay1={consumer.payrollDay1}
              consumerPayrollDay2={consumer.payrollDay2}
              getConsumer={getConsumer}
              profile={profile}
              getProfile={getProfile}
              setSpinner={setSpinner}
            />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeSubTab} tabId="all_cycles">
            <NewObjectAllCyclesSubTab profile={profile} />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeSubTab} tabId="payday_distribution">
            <NewObjectPaydaySubTab profile={profile} />
          </LazyTabPanel>
        </TabContent>
      </Spinner>
    </div>
  );
}

export default memo(NewObjectPayrollTab);
