import { useState } from 'react';
import { Button, Input, Label, Popover, PopoverBody } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';

function NewObjectPopoverNotificationForm({
  popoverOpen,
  handleToggle,
  getNotifications,
  consumerId,
}) {
  const { apiOriginNotifications } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [componentKey, setComponentKey] = useState(0);

  const reinitializeComponent = () => setComponentKey(prev => prev + 1);

  const [notification, setNotification] = useState({
    title: '',
    message: '',
    bodyType: 'TEXT',
    body: '',
    htmlBody: '',
    icon: 'GREEN_FREE_TEXT',
  });
  const [iframeValue, setIframeValue] = useState('');

  const close = () => {
    setNotification({
      title: '',
      message: '',
      bodyType: 'TEXT',
      body: '',
      htmlBody: '',
      icon: 'GREEN_FREE_TEXT',
    });
    setIframeValue('');

    handleToggle();
  };

  const sendFreeTextNotification = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const { title, message, bodyType, body, htmlBody, icon } = notification;

    const config = {
      token,
      url: `${apiOriginNotifications}/send_free_text_message`,
      method: 'POST',
      data: JSON.stringify({
        consumerId,
        title,
        message,
        bodyType,
        body,
        htmlBody: bodyType !== 'TEXT' ? htmlBody : undefined,
        icon,
      }),
    };

    try {
      await makeRequest(config);

      getNotifications();
      close();

      toast.success('Free text notification has been sent');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const updateField = (value, fieldName) => {
    if (fieldName === 'bodyType') {
      reinitializeComponent();
      setNotification(prev => ({
        ...prev,
        htmlBody: '',
        [fieldName]: value,
      }));
      setIframeValue('');
    } else {
      setNotification(prev => ({ ...prev, [fieldName]: value }));
    }
  };

  const disableButton = () => {
    if (notification.bodyType === 'TEXT') {
      return (
        notification.title.length === 0 ||
        notification.title.length > 50 ||
        notification.message.length === 0 ||
        notification.message.length > 150 ||
        notification.body.length === 0
      );
    }

    return (
      notification.title.length === 0 ||
      notification.title.length > 50 ||
      notification.message.length === 0 ||
      notification.message.length > 150 ||
      notification.htmlBody.length === 0
    );
  };

  return (
    <Popover
      key={componentKey}
      target="popover_notification_form"
      isOpen={popoverOpen}
      placement="auto"
      toggle={handleToggle}
      offset={[0, 0]}
      fade={false}
      hideArrow
    >
      <PopoverBody className="bo-new-object-popover-form-menu bo-w-640">
        <Spinner visible={spinner} size={36}>
          <Label className="bo-new-object-popover-form-menu-label">Title (20-50 characters):</Label>
          <Input
            type="text"
            className="bo-new-object-popover-form-menu-input"
            value={notification.title}
            onChange={e => updateField(e.target.value, 'title')}
          />
          <Label className="bo-new-object-popover-form-menu-label mt-2">
            Message (up to 150 characters):
          </Label>
          <Input
            type="text"
            className="bo-new-object-popover-form-menu-input"
            value={notification.message}
            onChange={e => updateField(e.target.value, 'message')}
          />
          <Label className="bo-new-object-popover-form-menu-label">Body Type:</Label>
          <Input
            type="select"
            className="bo-new-object-popover-form-menu-input"
            value={notification.bodyType}
            onChange={e => updateField(e.target.value, 'bodyType')}
          >
            <option value="TEXT">Text</option>
            <option value="HTML">HTML</option>
          </Input>
          <Label className="bo-new-object-popover-form-menu-label">
            {notification.bodyType === 'TEXT' ? 'Body:' : 'Textual Body (for old mobile versions):'}
          </Label>
          <Input
            type="textarea"
            className="bo-new-object-popover-form-menu-input bo-h-60-fixed bo-resize-none"
            value={notification.body}
            onChange={e => updateField(e.target.value, 'body')}
          />
          {notification.bodyType === 'HTML' && (
            <div className="d-flex w-100 justify-content-between">
              <div className="bo-w-300">
                <Label className="bo-new-object-popover-form-menu-label">HTML:</Label>
                <Input
                  type="textarea"
                  className={
                    'bo-new-object-popover-form-menu-input bo-h-200-fixed' +
                    ' bo-resize-none bo-new-object-text-xsm'
                  }
                  value={notification.htmlBody}
                  onChange={e => updateField(e.target.value, 'htmlBody')}
                />
              </div>
              <div>
                <div className="d-flex justify-content-between mt-1">
                  <Label className="bo-new-object-popover-form-menu-label">HTML Preview:</Label>
                  <Button
                    className="bo-new-object-inline-button bo-new-object-text-xsm"
                    onClick={() => setIframeValue(notification.htmlBody)}
                  >
                    Refresh
                  </Button>
                </div>
                <div className="bo-new-object-notification-iframe-wrapper">
                  <iframe
                    title="HTML Preview"
                    className="bo-new-object-notification-iframe"
                    srcDoc={iframeValue}
                  />
                </div>
              </div>
            </div>
          )}
          <Label className="bo-new-object-popover-form-menu-label">Icon Color:</Label>
          <Input
            type="select"
            className="bo-new-object-popover-form-menu-input"
            value={notification.icon}
            onChange={e => updateField(e.target.value, 'icon')}
          >
            <option value="GREEN_FREE_TEXT">Green</option>
            <option value="ORANGE_FREE_TEXT">Orange</option>
            <option value="RED_FREE_TEXT">Red</option>
          </Input>
          <div className="mt-3 d-flex justify-content-between">
            <Button
              className="bo-new-object-button"
              disabled={disableButton()}
              onClick={sendFreeTextNotification}
            >
              Send
            </Button>
            <Button className="bo-new-object-button" onClick={close}>
              Cancel
            </Button>
          </div>
        </Spinner>
      </PopoverBody>
    </Popover>
  );
}

export default NewObjectPopoverNotificationForm;
