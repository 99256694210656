import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// API
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/useAuth';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { makeRequest } from '../../utils/makeRequest';
// contexts
import { useClockContext } from '../../context/clock-context';
import { useEnv } from '../../context/env-context';
// components
import Loading from '../../components/Loading';
import { Spinner } from '../../components/Spinner';
import { Button, Col, Container, Row } from 'reactstrap';
// controllers
import ConsumerTabController from './components/ConsumerTabController';
import ConsumerFormController from './components/ConsumerFormController';
// modals
import ConsumerNotificationModal from './components/modals/ConsumerNotificationModal';
import ConsumerFundingModal from './components/modals/ConsumerFundingModal';
import ConsumerBridgePointsModal from './components/modals/ConsumerBridgePointsModal';
import ConsumerGhostingModal from './components/modals/ConsumerGhostingModal';
import ConsumerAlertModal from './components/modals/ConsumerNewAlertModal';
import ConsumerManualMfaModal from './components/modals/ConsumerManualMfaModal';
import ConsumerPhoneVerificationModal from './components/modals/ConsumerPhoneVerificationModal';
import ConsumerLinkNewCardModal from './components/modals/ConsumerLinkNewCardModal';

function Consumer() {
  const params = useParams();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginConsumer, apiOriginMonitor, apiOriginFunding } = useEnv();

  const { setConsumerLocation, setConsumerTimeZone } = useClockContext();

  const [spinner, setSpinner] = useState(false);
  const [consumer, setConsumer] = useState(null);
  const [openFunding, setOpenFunding] = useState(null);
  const [prediction, setPrediction] = useState(null);
  const [profile, setProfile] = useState(null);
  const [phoneFields, setPhoneFields] = useState({
    phoneCc: '',
    phoneNdc: '',
    phoneSn: '',
  });
  const [phoneEditMode, setPhoneEditMode] = useState(false);

  const [manualMfaModal, setManualMfaModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [fundingModal, setFundingModal] = useState({ isOpen: false, topup: false });
  const [bridgePointsModal, setBridgePointsModal] = useState(false);
  const [ghostingModal, setGhostingModal] = useState(false);
  const [newAlertModal, setNewAlertModal] = useState(false);
  const [phoneVerificationModal, setPhoneVerificationModal] = useState(false);
  const [linkNewCardModal, setLinkNewCardModal] = useState(false);

  useEffect(
    () => () => {
      setConsumerLocation({ city: null, state: null, country: 'US' });
      setConsumerTimeZone(null);
    },
    [setConsumerLocation, setConsumerTimeZone],
  );

  const changeValue = useCallback(
    e =>
      setConsumer(prevConsumer => ({
        ...prevConsumer,
        [e.target.name]: e.target.name === 'state' ? e.target.value.toUpperCase() : e.target.value,
      })),
    [],
  );

  const getPrediction = useCallback(
    async id => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return null;
      }

      const config = {
        token,
        url: `${apiOriginMonitor}/get_prediction_admin`,
        method: 'GET',
        params: { consumerId: id },
      };

      try {
        const response = await makeRequest(config);

        setPrediction(response);

        return response;
      } catch (error) {
        toast.error(error.message);

        return null;
      }
    },
    [getAccessTokenSilently, apiOriginMonitor],
  );

  const getProfile = useCallback(
    async id => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginMonitor}/get_profile`,
        method: 'GET',
        params: { consumerId: id },
      };

      try {
        const response = await makeRequest(config);

        setProfile(response);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [getAccessTokenSilently, apiOriginMonitor],
  );

  const getConsumer = useCallback(
    async id => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/get/${id}`,
        method: 'GET',
      };

      try {
        const response = await makeRequest(config);

        setConsumer(response);

        if (response && response.city && response.state) {
          setConsumerLocation(prev => ({ ...prev, city: response.city, state: response.state }));
        }
      } catch (error) {
        if (error.message === `Consumer ${id} not found!`) {
          navigate('/consumers');
          toast.error(`Consumer ID ${id} not found!`);
        } else {
          toast.error(error.message);
        }
      }
    },
    [getAccessTokenSilently, apiOriginConsumer, navigate, setConsumerLocation],
  );

  const getOpenFunding = useCallback(
    async id => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginFunding}/get_open_funding`,
        method: 'GET',
        params: { consumerId: id },
      };

      try {
        const data = await makeRequest(config);

        setOpenFunding(data);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [getAccessTokenSilently, apiOriginFunding],
  );

  const getFunding = useCallback(
    async id => {
      setSpinner(true);
      await getOpenFunding(id);
      setSpinner(false);
    },
    [getOpenFunding],
  );

  const refreshConsumer = useCallback(
    async id => {
      setSpinner(true);
      await getConsumer(id);
      await getOpenFunding(id);
      await getPrediction(id);
      await getProfile(id);
      setSpinner(false);
    },
    [getConsumer, getOpenFunding, getPrediction, getProfile],
  );

  useEffect(() => {
    refreshConsumer(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const refreshProfile = useCallback(async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginMonitor}/refresh_profile`,
        method: 'POST',
        data: JSON.stringify({ consumerId: consumer.id }),
      };

      try {
        await makeRequest(config);

        toast.success('Profile refresh job initiated successfully');
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  }, [getAccessTokenSilently, apiOriginMonitor, consumer?.id]);

  const predict = useCallback(async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/predict`,
      method: 'POST',
      data: JSON.stringify({ consumerId: consumer.id }),
    };

    try {
      await makeRequest(config);

      toast.success('Prediction job initiated successfully');
      getConsumer(consumer.id);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  }, [getAccessTokenSilently, apiOriginMonitor, consumer?.id, getConsumer]);

  const openManualMfaModal = useCallback(() => setManualMfaModal(true), []);
  const closeManualMfaModal = useCallback(() => setManualMfaModal(false), []);

  const openNotificationModal = useCallback(() => setNotificationModal(true), []);
  const closeNotificationModal = useCallback(() => setNotificationModal(false), []);

  const openFundingModal = useCallback(
    async topup => {
      setSpinner(true);
      await getPrediction(consumer.id);
      setSpinner(false);
      setFundingModal({ isOpen: true, topup });
    },
    [consumer?.id, getPrediction],
  );
  const closeFundingModal = useCallback(() => setFundingModal({ isOpen: false, topup: false }), []);

  const openBridgePointsModal = useCallback(() => setBridgePointsModal(true), []);
  const closeBridgePointsModal = useCallback(() => setBridgePointsModal(false), []);

  const openGhostingModal = useCallback(() => setGhostingModal(true), []);
  const closeGhostingModal = useCallback(() => setGhostingModal(false), []);

  const openNewAlertModal = useCallback(() => setNewAlertModal(true), []);
  const closeNewAlertModal = useCallback(() => setNewAlertModal(false), []);

  const openPhoneVerificationModal = useCallback(() => setPhoneVerificationModal(true), []);
  const closePhoneVerificationModal = useCallback(() => setPhoneVerificationModal(false), []);

  const openLinkNewCardModal = useCallback(() => setLinkNewCardModal(true), []);
  const closeLinkNewCardModal = useCallback(() => setLinkNewCardModal(false), []);

  if (!consumer) {
    return null;
  }

  return (
    <>
      <Spinner visible={spinner} text="">
        <Container className="mb-5">
          <Row>
            <Col className="bo-same-line">
              <h2>Consumer {consumer.isTest && <span className="text-danger">(TEST)</span>}</h2>
              <Button
                className="ms-3 bo-link"
                color="link"
                onClick={() => navigate(`/new-object/${params.id}`)}
              >
                Open in new design
              </Button>
            </Col>
          </Row>
          <ConsumerFormController
            consumer={consumer}
            changeValue={changeValue}
            setConsumer={setConsumer}
            getConsumer={getConsumer}
            openFunding={openFunding}
            getFunding={getFunding}
            openFundingModal={openFundingModal}
            setSpinner={setSpinner}
            refreshProfile={refreshProfile}
            predict={predict}
            openNotificationModal={openNotificationModal}
            openManualMfaModal={openManualMfaModal}
            phoneFields={phoneFields}
            setPhoneFields={setPhoneFields}
            phoneEditMode={phoneEditMode}
            setPhoneEditMode={setPhoneEditMode}
            openPhoneVerificationModal={openPhoneVerificationModal}
          />
          <ConsumerTabController
            consumer={consumer}
            setConsumer={setConsumer}
            getConsumer={getConsumer}
            predict={predict}
            profile={profile}
            getProfile={getProfile}
            refreshProfile={refreshProfile}
            openBridgePointsModal={openBridgePointsModal}
            bridgePointsModal={bridgePointsModal}
            openGhostingModal={openGhostingModal}
            openNewAlertModal={openNewAlertModal}
            openLinkNewCardModal={openLinkNewCardModal}
            linkNewCardModal={linkNewCardModal}
          />
        </Container>
      </Spinner>
      <ConsumerManualMfaModal
        isOpen={manualMfaModal}
        close={closeManualMfaModal}
        consumer={consumer}
        getConsumer={getConsumer}
      />
      <ConsumerNotificationModal
        isOpen={notificationModal}
        close={closeNotificationModal}
        setSpinner={setSpinner}
        consumerId={consumer?.id}
      />
      <ConsumerFundingModal
        consumer={consumer}
        isOpen={fundingModal.isOpen}
        topup={fundingModal.topup}
        close={closeFundingModal}
        getFunding={getFunding}
        prediction={prediction}
      />
      <ConsumerBridgePointsModal
        isOpen={bridgePointsModal}
        close={closeBridgePointsModal}
        consumer={consumer}
      />
      <ConsumerGhostingModal
        isOpen={ghostingModal}
        close={closeGhostingModal}
        consumer={consumer}
        getConsumer={getConsumer}
      />
      <ConsumerAlertModal
        isOpen={newAlertModal}
        close={closeNewAlertModal}
        consumer={consumer}
        getConsumer={getConsumer}
      />
      <ConsumerPhoneVerificationModal
        isOpen={phoneVerificationModal}
        close={closePhoneVerificationModal}
        consumer={consumer}
        phoneFields={phoneFields}
        setPhoneEditMode={setPhoneEditMode}
        getConsumer={getConsumer}
      />
      <ConsumerLinkNewCardModal
        isOpen={linkNewCardModal}
        close={closeLinkNewCardModal}
        consumer={consumer}
      />
    </>
  );
}

export default withAuthenticationRequired(Consumer, { onRedirecting: () => <Loading /> });
