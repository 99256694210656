import { useCallback, useEffect, useMemo, useRef } from 'react';

const SCROLLBAR_SIZE = 10;

function CustomTable({
  tableRows,
  tableColumns,
  width,
  height,
  rowKey,
  rowHeight = 36,
  getRowHeight,
  headerHeight = 36,
  headerTopBorder,
}) {
  const outerContainerRef = useRef(null);
  const headerGridRef = useRef(null);
  const mainGridRef = useRef(null);
  const mainGridContainerRef = useRef(null);

  const contentHeight = useMemo(() => tableRows.length * rowHeight, [tableRows, rowHeight]);
  const contentWidth = useMemo(
    () => tableColumns.reduce((acc, value) => acc + value.width, 0),
    [tableColumns],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const { current: outerGrid } = outerContainerRef;

    if (outerGrid) {
      const handler = e => {
        e.preventDefault();
        const { deltaX, deltaY } = e;

        const { current: grid } = mainGridRef;
        const { current: header } = headerGridRef;
        const { current: gridDiv } = mainGridContainerRef;

        const maxVScroll = gridDiv.clientHeight - grid.clientHeight;
        const maxHScroll = gridDiv.clientWidth - grid.clientWidth;

        if (gridDiv && grid && header) {
          let { scrollLeft, scrollTop } = grid;

          if (Math.abs(deltaY) > Math.abs(deltaX)) {
            scrollTop = scrollTop + deltaY < maxVScroll ? scrollTop + deltaY : maxVScroll;
          } else {
            scrollLeft = scrollLeft + deltaX < maxHScroll ? scrollLeft + deltaX : maxHScroll;
          }

          header.scrollLeft = scrollLeft;
          grid.scrollLeft = scrollLeft;
          grid.scrollTop = scrollTop;
        }
      };

      outerGrid.addEventListener('wheel', handler);

      return () => outerGrid.removeEventListener('wheel', handler);
    }
  }, []);

  const onScroll = useCallback(() => {
    const { scrollLeft } = mainGridRef.current;

    headerGridRef.current.scrollLeft = scrollLeft;
  }, []);

  const getColumnWidth = useCallback(
    index => {
      if (index === tableColumns.length - 1 && width > contentWidth) {
        return (
          width -
          contentWidth -
          (contentHeight + headerHeight > height ? SCROLLBAR_SIZE : 0) +
          tableColumns[tableColumns.length - 1].width
        );
      }

      return tableColumns[index].width;
    },
    [width, tableColumns, contentWidth, contentHeight, height, headerHeight],
  );

  return (
    <div ref={outerContainerRef} style={{ width }}>
      <div ref={headerGridRef} className="overflow-hidden">
        <table>
          <thead>
            <tr style={{ height: headerHeight }} className="d-flex">
              {tableColumns.map((col, colIdx) => (
                <th
                  // eslint-disable-next-line react/no-array-index-key
                  key={colIdx}
                  style={{
                    width:
                      tableColumns.length - 1 === colIdx && contentHeight > height - headerHeight
                        ? getColumnWidth(colIdx) + SCROLLBAR_SIZE
                        : getColumnWidth(colIdx),
                  }}
                  className={`bo-table-cell fw-bold px-1 overflow-hidden border-bottom ${
                    headerTopBorder ? 'border-top' : ''
                  }`}
                >
                  {col.header}
                </th>
              ))}
            </tr>
          </thead>
        </table>
      </div>
      <div
        ref={mainGridRef}
        style={{ height: height - headerHeight }}
        className="bo-table-container overflow-auto"
        onScroll={onScroll}
      >
        <table ref={mainGridContainerRef}>
          <tbody>
            {tableRows.map((row, rowIdx) => (
              <tr
                key={row[rowKey]}
                className="d-flex bo-custom-table-hover"
                style={{ height: getRowHeight ? getRowHeight(rowIdx) : rowHeight }}
              >
                {tableColumns.map((col, colIdx) => (
                  <td
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${row[rowKey]}-${colIdx}`}
                    className="bo-table-cell px-1 overflow-hidden"
                    style={{ width: getColumnWidth(colIdx) }}
                  >
                    {col.renderValue ? col.renderValue(row[col.key], row) : row[col.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CustomTable;
