import moment from 'moment';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import AutoSizer from 'react-virtualized-auto-sizer';
import NewObjectPopoverAlertForm from '../forms/NewObjectPopoverAlertForm';
import { Spinner } from '../../../../../components/Spinner';
import VirtualizedTable from '../../../../../components/VirtualizedTable';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';

function NewObjectAlertsTab({
  consumerId,
  consumerAlerts,
  getConsumer,
  refreshTab,
  setRefreshTab,
}) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const getAlerts = useCallback(async () => {
    setSpinner(true);
    await getConsumer(consumerId);
    setSpinner(false);
  }, [consumerId, getConsumer]);

  useEffect(() => {
    if (refreshTab === 'alerts') {
      getAlerts();
      setRefreshTab('');
    }
  }, [getAlerts, refreshTab, setRefreshTab]);

  const deleteAlert = useCallback(
    async alertId => {
      if (window.confirm('Are you sure?')) {
        setSpinner(true);
        const token = await getAccessTokenSilently();

        if (!token) {
          return;
        }

        const config = {
          token,
          url: `${apiOriginConsumer}/alerts/delete`,
          method: 'POST',
          data: JSON.stringify({ alertId }),
        };

        try {
          await makeRequest(config);
          await getAlerts();

          toast.success('Alert has been successfully deleted');
        } catch (error) {
          toast.error(error);
        } finally {
          setSpinner(false);
        }
      }
    },
    [apiOriginConsumer, getAccessTokenSilently, getAlerts],
  );

  const tableColumns = useMemo(
    () => [
      {
        key: 'type',
        header: 'Type',
        width: 180,
      },
      {
        key: 'data',
        header: 'Data',
        width: 180,
        renderValue: value =>
          value !== null && value !== undefined ? `Threshold=${value.threshold}` : '---',
      },
      {
        key: 'createTimestamp',
        header: 'Create Date',
        width: 220,
        renderValue: value =>
          value ? moment.utc(value).local().format('MMM DD, YYYY H:mm:ss (UTCZ)') : '---',
      },
      {
        key: 'user',
        header: 'Created By',
        width: 240,
      },
      {
        key: 'id',
        header: '',
        width: 100,
        renderValue: value => (
          <Button className="bo-new-object-inline-button" onClick={() => deleteAlert(value)}>
            Delete
          </Button>
        ),
      },
    ],
    [deleteAlert],
  );

  const handlePopoverToggle = () => setPopoverOpen(!popoverOpen);

  return (
    <div className="h-100 w-100">
      <div className="m-2">
        <Button id="popover_alert_form" className="bo-new-object-button" disabled={spinner}>
          Create New Alert
        </Button>
      </div>
      <NewObjectPopoverAlertForm
        popoverOpen={popoverOpen}
        handleToggle={handlePopoverToggle}
        consumerId={consumerId}
        getAlerts={getAlerts}
      />
      <AutoSizer>
        {({ height, width }) => (
          <Spinner visible={spinner} overlayWidth={width}>
            <VirtualizedTable
              tableRows={consumerAlerts}
              tableColumns={tableColumns}
              width={width}
              height={height - 42}
              rowKey="id"
              headerTopBorder
            />
          </Spinner>
        )}
      </AutoSizer>
    </div>
  );
}

export default memo(NewObjectAlertsTab);
