import { useState } from 'react';
import { Badge, Button } from 'reactstrap';
import { REPAYMENT_MODE_TEXT, STATUS_BADGE } from '../../../../../constants/fundings';
import { Link } from 'react-router-dom';

function NewObjectOpenFundingWidget({ openFunding }) {
  const [repaymentsOpen, setRepaymentsOpen] = useState(false);

  return (
    <div className="bo-border-container bg-light rounded w-100 p-0">
      <div className="p-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex gap-2">
            <p className="bo-new-object-text-bold">Open Advance</p>
            <Link to={`/funding/${openFunding.id}`} className="bo-new-object-link">
              #{openFunding.id.toString().padStart(6, '0')}
            </Link>
          </div>
          {STATUS_BADGE[openFunding.status] && (
            <Badge
              className="bo-new-object-inline-badge"
              color={STATUS_BADGE[openFunding.status]?.color}
            >
              {STATUS_BADGE[openFunding.status]?.text}
            </Badge>
          )}
        </div>
        <div className="mt-2 d-flex justify-content-between">
          <p className="bo-new-object-text">Total Amount:</p>
          <p className="bo-new-object-text">{`$${Number(openFunding.totalAmount).toLocaleString(
            'en-US',
          )}`}</p>
        </div>
        <div className="mt-2 d-flex justify-content-between">
          <p className="bo-new-object-text">Outstanding Amount:</p>
          <p className="bo-new-object-text">{`$${Number(
            openFunding.outstandingAmount,
          ).toLocaleString('en-US')}`}</p>
        </div>
        {openFunding.repaymentMode === 'NEXT_PAYDAY' && (
          <div className="mt-2 d-flex justify-content-between">
            <p className="bo-new-object-text">Next Repayment:</p>
            <p className="bo-new-object-text">{`$${Number(
              openFunding.outstandingAmount,
            ).toLocaleString('en-US')} on ${openFunding.nextRepaymentDate}`}</p>
          </div>
        )}
        {openFunding.repaymentMode === 'CUSTOM' && (
          <div className="mt-2 d-flex justify-content-between">
            <p className="bo-new-object-text">
              {openFunding.futureRepayments.length === 1 ? 'Next Repayment:' : 'Next Repayments:'}
            </p>
            {openFunding.futureRepayments.length > 0 && (
              <div className="d-flex align-items-center gap-1">
                <div className="d-flex flex-column align-items-end">
                  {!repaymentsOpen ? (
                    <p className="bo-new-object-text">
                      {`$${Number(openFunding.futureRepayments[0].amount).toLocaleString(
                        'en-US',
                      )} on ${openFunding.futureRepayments[0].repaymentDate}`}
                    </p>
                  ) : (
                    openFunding.futureRepayments.map(fR => (
                      <p key={fR.id} className="bo-new-object-text">
                        {`$${Number(fR.amount).toLocaleString('en-US')} on ${fR.repaymentDate}`}
                      </p>
                    ))
                  )}
                </div>
                <div className="d-flex align-self-end">
                  {openFunding.futureRepayments.length > 1 && (
                    <Button
                      className="bo-new-object-inline-button bo-w-50"
                      onClick={() => setRepaymentsOpen(!repaymentsOpen)}
                    >
                      {!repaymentsOpen ? 'more...' : 'less...'}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        <div className="mt-2 d-flex justify-content-between">
          <p className="bo-new-object-text">Repayment Mode:</p>
          <p
            className={`bo-new-object-text-bold ${
              REPAYMENT_MODE_TEXT[openFunding.repaymentMode].color
            }`}
          >
            {REPAYMENT_MODE_TEXT[openFunding.repaymentMode].text}
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewObjectOpenFundingWidget;
