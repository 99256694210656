import { useState, useMemo } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useEnv } from '../../../context/env-context';
import classnames from 'classnames';
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import LazyTabPanel from '../../../components/LazyTabPanel';
// tabs
import FundingDetails from './tabs/FundingDetails';
import FundingEventsLog from './tabs/FundingEventsLog';
import FundingSettings from './tabs/FundingSettings';
import FundingCustomRepayments from './tabs/FundingCustomRepayments';
import FundingPaymentLinks from './tabs/FundingPaymentLinks';
import FundingRepaymentActivity from './tabs/FundingRepaymentActivity';

function FundingTabController({
  funding,
  setFunding,
  getFunding,
  consumer,
  setSpinner,
  setTotalExpectedBalance,
  openPaymentLinksModal,
}) {
  const { user } = useAuth();
  const { audience } = useEnv();

  const [activeTab, setActiveTab] = useState('details');

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const role = useMemo(() => user[`${audience}/metadata`].role, [user, audience]);

  return (
    <>
      <Nav className="bo-pointer mt-5" tabs>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 'details',
            })}
            onClick={() => toggle('details')}
          >
            Details
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 'repayment_activity',
            })}
            onClick={() => toggle('repayment_activity')}
          >
            Repayment Activity
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 'eventsLog',
            })}
            onClick={() => toggle('eventsLog')}
          >
            Events Log
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 'settings',
            })}
            onClick={() => toggle('settings')}
          >
            Settings
          </NavLink>
        </NavItem>
        {role === 'Superuser' && (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === 'paymentLinks',
              })}
              onClick={() => toggle('paymentLinks')}
            >
              Payment Links
            </NavLink>
          </NavItem>
        )}
        {funding.repaymentMode === 'CUSTOM' && (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === 'customRepayments',
              })}
              onClick={() => toggle('customRepayments')}
            >
              Custom Repayments
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab} className="bo-tabs">
        <LazyTabPanel activeTab={activeTab} tabId="details">
          <FundingDetails
            fundingId={funding.id}
            fundingEvents={funding.events}
            getFunding={getFunding}
            isConsumer={!!consumer}
            consumerId={consumer?.id}
            consumerIsTest={consumer?.isTest}
            setSpinner={setSpinner}
          />
        </LazyTabPanel>
        <LazyTabPanel activeTab={activeTab} tabId="repayment_activity">
          <FundingRepaymentActivity fundingId={funding.id} />
        </LazyTabPanel>
        <LazyTabPanel activeTab={activeTab} tabId="eventsLog">
          <FundingEventsLog fundingLog={funding.log} />
        </LazyTabPanel>
        <LazyTabPanel activeTab={activeTab} tabId="settings">
          <FundingSettings
            fundingId={funding.id}
            fundingFreeCreditTransfers={funding.freeCreditTransfers}
            fundingFreeDebitTransfers={funding.freeDebitTransfers}
            fundingTransferFee={funding.transferFee}
            setFunding={setFunding}
            getFunding={getFunding}
            setSpinner={setSpinner}
          />
        </LazyTabPanel>
        {role === 'Superuser' && (
          <LazyTabPanel activeTab={activeTab} tabId="paymentLinks">
            <FundingPaymentLinks
              fundingId={funding.id}
              fundingPaymentLinks={funding.paymentLinks}
              getFunding={getFunding}
              setSpinner={setSpinner}
              openPaymentLinksModal={openPaymentLinksModal}
            />
          </LazyTabPanel>
        )}
        {funding.repaymentMode === 'CUSTOM' && (
          <LazyTabPanel activeTab={activeTab} tabId="customRepayments">
            <FundingCustomRepayments
              fundingFutureRepayments={funding.futureRepayments}
              fundingRepaymentMode={funding.repaymentMode}
              fundingId={funding.id}
              fundingConsumerId={funding.consumerId}
              fundingCurrentAmount={funding.currentAmount}
              isConsumer={!!consumer}
              consumerId={consumer?.id}
              setActiveTab={setActiveTab}
              getFunding={getFunding}
              setTotalExpectedBalance={setTotalExpectedBalance}
            />
          </LazyTabPanel>
        )}
      </TabContent>
    </>
  );
}

export default FundingTabController;
