import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualizedTable from '../../../../../../components/VirtualizedTable';
import { memo, useMemo } from 'react';
import { weekdayArray } from '../../../../../../utils/dates';

function NewObjectPaydaySubTab({ profile }) {
  const paydayDistribution = useMemo(() => {
    if (profile && profile.payroll && profile.payroll.daysDistribution) {
      return [
        ...Object.entries(profile.payroll.daysDistribution).map(([key, value]) => {
          const day = (() => {
            if (profile.payroll.frequency === 'WEEKLY') {
              return `${weekdayArray[key]} (${key})`;
            }

            if (profile.payroll.frequency === 'BIWEEKLY') {
              return `${weekdayArray[key % 7]} (${key})`;
            }

            return key === -1 ? `Last day of month (${key})` : key;
          })();

          return {
            day,
            count: value,
            uuid: crypto.randomUUID(),
          };
        }),
        {
          day: '* Based on last 10 transactions',
          count: null,
          uuid: crypto.randomUUID(),
        },
      ];
    }

    return [];
  }, [profile]);

  const tableColumns = useMemo(
    () => [
      {
        key: 'day',
        header: 'Day',
        width: 200,
        renderValue: value => (
          <p
            className={`p-0 m-0 ${value === '* Based on last 10 transactions' ? 'fst-italic' : ''}`}
          >
            {value}
          </p>
        ),
      },
      {
        key: 'count',
        header: 'Count',
        width: 80,
      },
    ],
    [],
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <VirtualizedTable
          tableRows={paydayDistribution}
          tableColumns={tableColumns}
          width={width}
          height={height}
          rowKey="uuid"
        />
      )}
    </AutoSizer>
  );
}

export default memo(NewObjectPaydaySubTab);
