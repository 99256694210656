import { useState } from 'react';
import { Badge, Button, Popover, PopoverBody } from 'reactstrap';
import { BANK_ACCOUNT_STATUS_BADGE } from '../../../../../constants/consumers';
import { Spinner } from '../../../../../components/Spinner';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';

function NewObjectPopoverBankStatusForm({ consumerId, consumerBankAccountStatus, setConsumer }) {
  const [isOpen, setIsOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const handleToggle = () => {
    if (
      consumerBankAccountStatus !== 'NOT_CONNECTED' &&
      consumerBankAccountStatus !== 'NO_TRANSFERS'
    ) {
      setIsOpen(!isOpen);
    }
  };

  const handleBankStatusClick = async bankStatus => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/set_bank_account_status`,
      method: 'POST',
      data: JSON.stringify({ consumerId, status: bankStatus }),
    };

    try {
      await makeRequest(config);
      setConsumer(prevConsumer => ({ ...prevConsumer, bankAccountStatus: bankStatus }));

      toast.success(`Bank Account Status set to ${bankStatus}`);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
      setIsOpen(false);
    }
  };

  if (!BANK_ACCOUNT_STATUS_BADGE[consumerBankAccountStatus]) {
    return null;
  }

  return (
    <>
      <Button
        id="popover_bank_status_form"
        onClick={handleToggle}
        className="bo-new-object-popover-form-toggle me-2"
      >
        <Badge
          className={`bo-new-object-inline-badge px-0 bo-w-100
            ${BANK_ACCOUNT_STATUS_BADGE[consumerBankAccountStatus]?.color}`}
        >
          {BANK_ACCOUNT_STATUS_BADGE[consumerBankAccountStatus]?.text}
        </Badge>
      </Button>
      <Popover
        target="popover_bank_status_form"
        isOpen={isOpen}
        placement="bottom-start"
        toggle={handleToggle}
        offset={[0, 6]}
        fade={false}
        hideArrow
      >
        <PopoverBody className="bo-new-object-popover-form-menu">
          <Spinner visible={spinner} size={36}>
            <div className="d-flex flex-column">
              {Object.entries(BANK_ACCOUNT_STATUS_BADGE)
                .filter(([key]) => key !== 'NOT_CONNECTED' && key !== 'NO_TRANSFERS')
                .map(([key, value]) => (
                  <Button
                    key={key}
                    className="bo-new-object-base-button mb-2"
                    onClick={() => handleBankStatusClick(key)}
                  >
                    <Badge
                      className={`bo-inline-badge bo-new-object-inline-badge-hover shadow-sm
                         bo-w-120 ${value?.color}`}
                    >
                      {value?.text}
                    </Badge>
                  </Button>
                ))}
            </div>
            <div className="mt-2 d-flex justify-content-center">
              <Button className="bo-new-object-button" onClick={handleToggle}>
                Cancel
              </Button>
            </div>
          </Spinner>
        </PopoverBody>
      </Popover>
    </>
  );
}

export default NewObjectPopoverBankStatusForm;
